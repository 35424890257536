import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Typography,
  AppBar,
  Button,
  Avatar,
  Paper,
  Toolbar,
  Divider,
  Modal,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  useTheme,
  CardMedia,
  CardContent,
  // Add these imports
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { keyframes, styled, useMediaQuery } from "@mui/system";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ChatBubbleOutline, WarningAmber } from "@mui/icons-material";
import { COLORS } from "../utils/COLORS";
import LogoImgShortDark from "../assets/trans_logo_short.png";
import { useParams } from "react-router-dom";
import { ImageList, ImageListItem } from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import GlobalStateContext from "../context/globalStateContext";
import PledgeModal from "../components/PledgeModal";
import UserContext from "../context/userContext";
import AuthModal from "../components/AuthModal";
import CustomToolbar from "../components/CustomToolbar";
import ClaimFundsModal from "../components/ClaimFundsModal";
import AddCardModal from "../components/AddCardModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { storage } from "../firebaseConfig";
import { off, onValue, ref, get } from "firebase/database";
import { database } from "../firebaseConfig";
import RescueDetailsModal from "../components/RescueDetailsModal";
import SendIcon from "@mui/icons-material/Send";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { CircularProgress } from "@mui/material";
import NiceAvatar, { genConfig } from "react-nice-avatar";
import { motion } from "framer-motion";
import { Snackbar } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Footer from "../components/Footer";
import { calculateCountdown } from "../utils/HELPER_FUNCTIONS";
import { firebase } from "../firebaseConfig";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  // "pk_test_51Q5TzfIWBW0yKcNbXRKebR5EkZEk19VJZqyWSoabnT760WQgR7GGadARjL9gZpAzBvkCvmHK2xlDxHXMYVPItEPN00Mh95kJcQ"
);

const DesktopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: "1200px",
  margin: "0 auto",
  padding: theme.spacing(3),
}));

const LeftColumn = styled(Box)(({ theme }) => ({
  width: "65%",
  marginRight: theme.spacing(3),
}));

const RightColumn = styled(Box)(({ theme }) => ({
  width: "35%",
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
}));

const ShareModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
  width: "90%",
  maxWidth: "400px",
  textAlign: "center",
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(1),
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
}));

const ShareButton = styled(Button)(({ theme }) => ({
  backgroundColor: COLORS.blue,
  color: "white",
  fontWeight: "bold",
  borderRadius: "20px",
  padding: "8px 16px",
  marginBottom: "16px",
  "&:hover": {
    backgroundColor: COLORS.darkBlue,
  },
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
  width: "90%",
  maxWidth: "400px",
  maxHeight: "80vh",
  overflowY: "auto",
  textAlign: "center",
}));

const UserInfoContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "16px",
});

const AvatarContainer = styled(Box)({
  marginRight: "12px",
});

const PageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  justifyContent: "flex-start",
  alignItems: "center",
  backgroundColor: "#ffffff",
  boxSizing: "border-box", // Add this line
}));

const ContentWrapper = styled(Box)({
  flex: 1,
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

const Card = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: "16px",
  overflow: "hidden",
  marginTop: theme.spacing(2),
  marginBottom: 100,
  boxSizing: "border-box",
  // Remove any border
  border: "none",
  // Explicitly set box-shadow to none
  boxShadow: "none",
  // Ensure background is solid
  backgroundColor: "#ffffff",
  // Override any elevation
  elevation: 0,
}));

const ScrollableCardContent = styled(Box)(({ theme }) => ({
  // maxHeight: "calc(100vh - 200px)", // Adjust this value as needed
  overflowY: "auto",
  overflowX: "hidden",
  height: "100%",
  width: "90%",
  padding: theme.spacing(3, 3, 3, 3),
  "&::-webkit-scrollbar": {
    width: "100%",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    borderRadius: "10px",
  },
}));

const Header = styled(AppBar)({
  backgroundColor: COLORS.yellow,
  boxShadow: "none",
});

const TabContainer = styled(Box)({
  display: "flex",
  marginBottom: "24px",
  backgroundColor: "#f5f5f5",
  borderRadius: "20px",
  padding: "4px",
  justifyContent: "space-between",
});

const Tab = styled(Button)(({ theme, variant }) => ({
  borderRadius: "16px",
  textTransform: "none",
  padding: "8px 16px",
  fontSize: "14px",
  fontWeight: "medium",
  flex: 1,
  color:
    variant === "contained"
      ? theme.palette.primary.contrastText
      : theme.palette.text.secondary,
  backgroundColor:
    variant === "contained" ? theme.palette.primary.main : "transparent",
  "&:hover": {
    backgroundColor:
      variant === "contained" ? theme.palette.primary.dark : "#e0e0e0",
  },
  "&:not(:last-child)": {
    marginRight: "4px",
  },
}));

const TopUserContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "24px",
});

const UserInfo = styled(Box)({
  // marginLeft: "16px",
  display: "flex",
  width: "100%",
  flexDirection: "column",
});

const ActivityInputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(3),
  gap: theme.spacing(2),
}));

const ActivityTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: "20px",
    backgroundColor: "#f5f5f5",
    "&.Mui-focused fieldset": {
      borderColor: COLORS.yellow,
    },
  },
}));

const ActionRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: "20px",
  padding: "10px 20px",
  backgroundColor: COLORS.yellow,
  color: "black",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: COLORS.darkYellow,
  },
}));

const ImagePreview = styled("img")({
  width: "100%",
  maxHeight: "200px",
  objectFit: "cover",
  borderRadius: "8px",
  marginTop: "8px",
});

const TotalUsersContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  marginBottom: "16px",
});
const UserAvatars = styled(Box)({
  display: "flex",
  marginBottom: "16px",
});
const ViewAllButton = styled(Button)({
  textTransform: "none",
});
const PercentageBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  color: "#4caf50",
  fontSize: "14px",
  fontWeight: "bold",
});

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  marginBottom: 0,
  lineHeight: 1.6,
  overflow: "hidden",
  display: "-webkit-box",
  "-webkit-line-clamp": 3,
  "-webkit-box-orient": "vertical",
}));

const ReadMoreButton = styled(Button)(({ theme }) => ({
  padding: 0,
  marginTop: 0,
  marginBottom: "24px",
  textTransform: "none",
  fontWeight: "bold",
  color: COLORS.lightGray,
  alignSelf: "flex-end",
}));

const ActivityPost = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  padding: theme.spacing(2),
  backgroundColor: "white",
  border: `1px solid ${COLORS.lightGray2}`,
  borderRadius: "8px",
}));

const PostDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const PostDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const SharesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(1),
}));

const SharesTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const InteractionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: theme.spacing(1),
  width: "100%",
}));

const InteractionItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const InteractionTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.3; }
  100% { opacity: 1; }
`;

const SimilarDogsSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: 0, // theme.spacing(4),
  position: 'relative',
}));

const SimilarDogsContainer = styled(Box)({
  display: 'flex',
  overflowX: 'auto',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
});

const SimilarDogCard = styled(Card)(({ theme }) => ({
  minWidth: 200,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  marginRight: theme.spacing(2),
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  },
}));

const SimilarDogImage = styled(CardMedia)({
  height: 140,
  objectFit: 'cover',
});

const ScrollButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
}));

const StatusBadge = styled(Box)(({ status }) => ({
  position: 'absolute',
  top: '10px',
  right: '10px',
  padding: '4px 8px',
  borderRadius: '12px',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  color: '#fff',
  backgroundColor:
    status === 'rescued'
      ? COLORS.primaryGreen
      : status === 'pending'
      ? COLORS.orange
      : COLORS.secondary,
}));

const DogProfilePage = () => {
  const { slug } = useParams();
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [dogProfile, setDogProfile] = useState(null);
  const [activeTab, setActiveTab] = useState("profile");
  const [countdown, setCountdown] = useState("");
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [pledgeData, setPledgeData] = useState(null);
  const [totalPledged, setTotalPledged] = useState(0);
  const [topPledger, setTopPledger] = useState(null);
  const [pledgerCount, setPledgerCount] = useState(0);
  const [newActivity, setNewActivity] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [username, setUsername] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showFosterModal, setShowFosterModal] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [fosterFormData, setFosterFormData] = useState({
    fullName: "",
    address: "",
    residenceType: "",
    phoneNumber: "",
  });
  const [submittingFoster, setSubmittingFoster] = useState(false);
  const [fosterSnackbarOpen, setFosterSnackbarOpen] = useState(false);
  const fileInputRef = useRef(null);
  const avatarConfig = useMemo(() => genConfig(), []);
  const descriptionRef = useRef(null);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [similarDogs, setSimilarDogs] = useState([]);
  const scrollContainerRef = useRef(null);

  const postAvatarConfigs = useMemo(() => {
    const configs = {};
    Object.values(dogProfile?.posts || {}).forEach((post) => {
      configs[post.id] = genConfig();
    });
    return configs;
  }, [dogProfile?.posts]);

  useEffect(() => {
    const fetchSimilarDogs = async () => {
      const petsSnapshot = await firebase.database().ref('availablePets').limitToLast(10).once('value');
      const petsData = petsSnapshot.val();
      const petsArray = petsData ? Object.entries(petsData).map(([id, pet]) => ({ id, ...pet })) : [];
      setSimilarDogs(petsArray.filter(pet => pet.id !== dogProfile?.id));
    };

    if (dogProfile) {
      fetchSimilarDogs();
    }
  }, [dogProfile]);

  useEffect(() => {
    if (descriptionRef.current) {
      const isOverflowing =
        descriptionRef.current.scrollHeight >
        descriptionRef.current.clientHeight;
      setShowFullDescription(!isOverflowing);
    }
  }, [dogProfile?.description]);

  useEffect(() => {
    const dogRef = ref(database, `availablePets/${slug}`);

    const unsubscribe = onValue(
      dogRef,
      async (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Fetch shelter information
          if (data.shelterId) {
            const shelterRef = ref(
              database,
              `availableShelters/${data.shelterId}`
            );
            try {
              const shelterSnapshot = await get(shelterRef);
              const shelterData = shelterSnapshot.val();

              if (shelterData) {
                data.shelter = shelterData;
              }
            } catch (error) {
              console.error("Error fetching shelter data:", error);
            }
          }
          setDogProfile(data);
        } else {
          setDogProfile(null);
        }
      },
      (error) => {
        console.error("Error fetching dog profile:", error);
        setDogProfile(null);
      }
    );

    // Cleanup function
    return () => {
      off(dogRef);
    };
  }, [slug]);

  useEffect(() => {
    if (!dogProfile) return;

    const pledgesRef = ref(
      database,
      `availablePets/${dogProfile?.slug}/pledges`
    );

    const unsubscribe = onValue(pledgesRef, (snapshot) => {
      const pledgesData = snapshot.val();

      if (pledgesData) {
        // Calculate total pledged amount
        const total = Object.values(pledgesData).reduce(
          (sum, pledge) => sum + pledge.displayAmount,
          0
        );
        setTotalPledged(total);

        // Find top pledger
        const top = Object.values(pledgesData).reduce(
          (top, pledge) => (pledge.amount > top.amount ? pledge : top),
          { amount: 0 }
        );
        setTopPledger(top);

        // Count number of pledgers
        setPledgerCount(Object.keys(pledgesData).length);
      } else {
        setTotalPledged(0);
        setTopPledger(null);
        setPledgerCount(0);
      }
    });

    return () => off(pledgesRef);
  }, [dogProfile]);

  useEffect(() => {
    if (!dogProfile) return;
    const timer = setInterval(() => {
      setCountdown(calculateCountdown(dogProfile));
    }, 1000);

    return () => clearInterval(timer);
  }, [dogProfile]);

  
  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200; // Adjust this value to change scroll distance
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };


  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleSubmitActivity = async () => {
    if (!user) {
      setGlobalState({
        ...globalState,
        showAuthModal: true,
      });
      return;
    }
    if (!newActivity.trim()) {
      alert("Please enter some text for your post.");
      return;
    }

    if (user && !user.username) {
      setShowUsernameModal(true);
      return;
    }

    setUploading(true);

    try {
      let imageUrl = null;
      if (selectedImage) {
        const storageRef = storage.ref(
          `post_images/${dogProfile.id}/${Date.now()}`
        );
        const uploadTask = await storageRef.put(selectedImage);
        imageUrl = await uploadTask.ref.getDownloadURL();
      }

      const newPost = {
        date: new Date().toISOString(),
        description: newActivity,
        id: Date.now().toString(),
        images: imageUrl ? [imageUrl] : [],
        username: user.username,
      };

      await database
        .ref(`availablePets/${dogProfile.slug}/posts`)
        .push(newPost);

      // Reset the input fields after submission
      setNewActivity("");
      setSelectedImage(null);
    } catch (error) {
      console.error("Error submitting post:", error);
      alert("An error occurred while submitting your post. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const handleFosterClick = () => {
    if (!user) {
      // no user
      setGlobalState({
        ...globalState,
        showAuthModal: true,
      });
    } else {
      setShowFosterModal(true);
    }
  };

  const handleSetUsername = async () => {
    if (username.trim()) {
      try {
        await database.ref(`users/${user.uid}`).update({ username });
        setUser({ ...user, username });
        setShowUsernameModal(false);
      } catch (error) {
        console.error("Error setting username:", error);
        alert(
          "An error occurred while setting your username. Please try again."
        );
      }
    }
  };

  const handleShare = () => {
    setShowShareModal(true);
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  const shareUrl = dogProfile && `https://www.paws.lol/${dogProfile?.slug}`;

  const handleSocialShare = (platform) => {
    let shareLink;
    switch (platform) {
      case "facebook":
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        break;
      case "twitter":
        shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          shareUrl
        )}&text=${encodeURIComponent(`Help save ${dogProfile?.name}!`)}`;
        break;
      case "linkedin":
        shareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          shareUrl
        )}&title=${encodeURIComponent(`Help save ${dogProfile?.name}!`)}`;
        break;
      case "whatsapp":
        shareLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `Help save ${dogProfile?.name}! ${shareUrl}`
        )}`;
        break;
      case "email":
        shareLink = `mailto:?subject=${encodeURIComponent(
          `Help save ${dogProfile?.name}!`
        )}&body=${encodeURIComponent(
          `Check out this dog that needs help: ${shareUrl}`
        )}`;
        break;
      default:
        return;
    }
    window.open(shareLink, "_blank");
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setSnackbarOpen(true);
    });
  };

  const residenceTypes = [
    "House with yard",
    "House without yard",
    "Apartment",
    "Condo",
    "Townhouse",
    "Mobile home",
    "Farm/Rural property",
    "Other",
  ];

  const handleFosterSubmit = async () => {
    if (!user) {
      alert("Please log in to submit a foster application.");
      return;
    }

    setSubmittingFoster(true);

    try {
      // Save foster application data to user's profile
      await database
        .ref(`users/${user.uid}/fosterApplications/${dogProfile.id}`)
        .set({
          ...fosterFormData,
          dogId: dogProfile.id,
          dogSlug: dogProfile.slug,
          dogName: dogProfile.name,
          appliedAt: new Date().toISOString(),
        });

      // Update the dog's profile to indicate a foster application
      await database
        .ref(`availablePets/${dogProfile.id}/fosterApplications/${user.uid}`)
        .set({
          userId: user.uid,
          ...fosterFormData,
          appliedAt: new Date().toISOString(),
        });

      // await database.ref(`availablePets/${dogProfile.id}/posts`).push(newPost);

      // Close the modal and show success message
      setShowFosterModal(false);
      setFosterSnackbarOpen(true);

      // Reset form data
      setFosterFormData({
        fullName: "",
        address: "",
        residenceType: "",
        phoneNumber: "",
      });
    } catch (error) {
      console.error("Error submitting foster application:", error);
      alert(
        "An error occurred while submitting your application. Please try again."
      );
    } finally {
      setSubmittingFoster(false);
    }
  };

  const getKillDate = (pet) => {
    if (!pet.euthanasiaDate) return "Missing date";

    const today = new Date();
    const [year, month, day] = pet.euthanasiaDate.split("-").map(Number);
    const killDate = new Date(Date.UTC(year, month - 1, day));

    const tomorrow = new Date(today);
    tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);

    const formattedDate = killDate.toLocaleDateString("en-US", {
      timeZone: "UTC",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    if (
      today.getUTCFullYear() === killDate.getUTCFullYear() &&
      today.getUTCMonth() === killDate.getUTCMonth() &&
      today.getUTCDate() === killDate.getUTCDate()
    ) {
      return `TODAY (${formattedDate})`;
    } else if (
      tomorrow.getUTCFullYear() === killDate.getUTCFullYear() &&
      tomorrow.getUTCMonth() === killDate.getUTCMonth() &&
      tomorrow.getUTCDate() === killDate.getUTCDate()
    ) {
      return `TOMORROW (${formattedDate})`;
    }
    return formattedDate;
  };

  const renderSimilarDogs = () => (
    <SimilarDogsSection>
      <Divider sx={{ mb: 2, mt: 2 }} />
      <Typography variant="h6" sx={{ marginBottom: 2, marginTop: 10, fontWeight: 'bold', color: COLORS.secondary }}>
        Browse Urgent Need Dogs
      </Typography>
      <Box sx={{ position: 'relative' }}>
        <ScrollButton
          onClick={() => handleScroll('left')}
          sx={{ left: -20 }}
          size="small"
        >
          <ArrowBackIosNewIcon />
        </ScrollButton>
        <ScrollButton
          onClick={() => handleScroll('right')}
          sx={{ right: -20 }}
          size="small"
        >
          <ArrowForwardIosIcon />
        </ScrollButton>
        <SimilarDogsContainer ref={scrollContainerRef}>
          {similarDogs.map((dog) => (
            <SimilarDogCard key={dog.id} onClick={() => window.open(`/${dog.slug}`, '_blank')}>
            <Box sx={{ position: 'relative' }}>
              <CardMedia
                component="img"
                height="140"
                image={dog.images?.[0] || "https://via.placeholder.com/300x225"}
                alt={dog.name}
              />
              <StatusBadge status={getStatusText(dog).toLowerCase()}>
                {getStatusText(dog)}
              </StatusBadge>
            </Box>
            <CardContent>
              <Typography gutterBottom variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
                {dog.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {dog.breed} • {dog.age} • {dog.weight} lbs
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mt: 1,
                  color: COLORS.secondary,
                  animation: calculateCountdown(dog) !== "Time expired"
                    ? `${blinkAnimation} 1.5s ease-in-out infinite`
                    : "none",
                }}
              >
                Kill Date: {getKillDate(dog)}
              </Typography>
            </CardContent>
          </SimilarDogCard>
          ))}
        </SimilarDogsContainer>
      </Box>
    </SimilarDogsSection>
  );

  const renderFosterModal = () => {
    return (
      <StyledModal
        open={showFosterModal}
        onClose={() => setShowFosterModal(false)}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
      >
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          onClick={() => setShowFosterModal(false)}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: window.innerWidth > 600 ? "center" : "flex-end",
            height: "100%",
          }}
        >
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <Typography variant="h5" gutterBottom align="center">
              Foster {dogProfile?.name}
            </Typography>
            <Typography variant="body1" paragraph align="center">
              We need more people like you
            </Typography>
            <TextField
              fullWidth
              label="Full Name"
              variant="outlined"
              margin="normal"
              value={fosterFormData.fullName}
              onChange={(e) =>
                setFosterFormData({
                  ...fosterFormData,
                  fullName: e.target.value,
                })
              }
            />
            <TextField
              fullWidth
              label="Address"
              variant="outlined"
              margin="normal"
              value={fosterFormData.address}
              onChange={(e) =>
                setFosterFormData({
                  ...fosterFormData,
                  address: e.target.value,
                })
              }
            />
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Residence Type</InputLabel>
              <Select
                value={fosterFormData.residenceType}
                onChange={(e) =>
                  setFosterFormData({
                    ...fosterFormData,
                    residenceType: e.target.value,
                  })
                }
                label="Residence Type"
              >
                {residenceTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Phone Number"
              variant="outlined"
              margin="normal"
              value={fosterFormData.phoneNumber}
              onChange={(e) =>
                setFosterFormData({
                  ...fosterFormData,
                  phoneNumber: e.target.value,
                })
              }
            />
            <Button
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
                backgroundColor: COLORS.primaryGreen,
                "&:hover": {
                  backgroundColor: COLORS.darkGreen,
                },
              }}
              onClick={handleFosterSubmit}
              disabled={submittingFoster}
            >
              {submittingFoster ? "Submitting..." : "Submit Foster Application"}
            </Button>
          </ModalContent>
        </motion.div>
      </StyledModal>
    );
  };

  const renderShareModal = () => {
    return (
      <StyledModal
        open={showShareModal}
        onClose={handleCloseShareModal}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
      >
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          onClick={() => setShowShareModal(false)}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: window.innerWidth > 600 ? "center" : "flex-end",
            height: "100%",
          }}
        >
          <ShareModalContent onClick={(e) => e.stopPropagation()}>
            <Typography variant="h5" sx={{ mb: 3 }}>
              Help save {dogProfile?.name} by sharing
            </Typography>
            {dogProfile && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  padding: "8px 12px",
                  backgroundColor: "#f5f5f5",
                  marginTop: 2,
                  marginBottom: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: "#1976d2",
                    fontWeight: 500,
                  }}
                >
                  <a
                    href={`https://www.paws.lol/${dogProfile.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {`https://www.paws.lol/${dogProfile.slug}`}
                  </a>
                </Typography>
                <IconButton
                  onClick={() => {
                    const fullUrl = `https://www.paws.lol/dogProfile/${dogProfile.slug}`;
                    navigator.clipboard
                      .writeText(fullUrl)
                      .then(() =>
                        setSnackbar({
                          open: true,
                          message: "Link copied to clipboard!",
                          severity: "success",
                        })
                      )
                      .catch(() =>
                        setSnackbar({
                          open: true,
                          message: "Failed to copy link. Please try again.",
                          severity: "error",
                        })
                      );
                  }}
                  aria-label="Copy link"
                  sx={{
                    color: "#757575",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Box>
            )}

            <Box>
              <SocialIconButton
                onClick={() => handleSocialShare("facebook")}
                aria-label="share on Facebook"
              >
                <FacebookIcon sx={{ color: "#3b5998" }} />
              </SocialIconButton>
              <SocialIconButton
                onClick={() => handleSocialShare("twitter")}
                aria-label="share on Twitter"
              >
                <TwitterIcon sx={{ color: "#1da1f2" }} />
              </SocialIconButton>
              <SocialIconButton
                onClick={() => handleSocialShare("linkedin")}
                aria-label="share on LinkedIn"
              >
                <LinkedInIcon sx={{ color: "#0077b5" }} />
              </SocialIconButton>
              {/* <SocialIconButton onClick={() => handleSocialShare('whatsapp')} aria-label="share on WhatsApp">
                <WhatsApp sx={{ color: '#25d366' }} />
              </SocialIconButton> */}
              <SocialIconButton
                onClick={() => handleSocialShare("email")}
                aria-label="share via Email"
              >
                <EmailIcon sx={{ color: "#d44638" }} />
              </SocialIconButton>
              <SocialIconButton onClick={handleCopyLink} aria-label="copy link">
                <ContentCopyIcon />
              </SocialIconButton>
            </Box>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Help {dogProfile?.name} get rescued!
            </Typography>
          </ShareModalContent>
        </motion.div>
      </StyledModal>
    );
  };

  const renderEnterUserNameModal = () => {
    return (
      <StyledModal
        open={showUsernameModal}
        onClose={() => setShowUsernameModal(false)}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
      >
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          onClick={() => setShowUsernameModal(false)}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: isMobileView ? "flex-end" : "center",
            height: "100%",
          }}
        >
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: COLORS.primaryGreen, mb: 3 }}
            >
              Set Your Username
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{ mb: 3 }}
            />
            <Button
              variant="contained"
              fullWidth
              onClick={handleSetUsername}
              sx={{
                backgroundColor: COLORS.primaryGreen,
                "&:hover": {
                  backgroundColor: COLORS.darkGreen,
                },
              }}
            >
              Set Username
            </Button>
          </ModalContent>
        </motion.div>
      </StyledModal>
    );
  };

  const getStatusText = (dogProfile) => {
    const countdown = calculateCountdown(dogProfile);
    if (dogProfile?.euthanasiaStatus === "rescued") {
      return "Rescued";
    }

    if (countdown === "Time expired") {
      if (
        !dogProfile?.euthanasiaDate ||
        dogProfile?.euthanasiaDate.length === 0
      ) {
        return "Missing euthanasia date";
      }
      return "Expired :(";
    }

    if (
      countdown === "0d 0h 0m 0s" ||
      countdown === "0h 0m 0s" ||
      countdown === "0m 0s" ||
      countdown === "0s"
    ) {
      return "TODAY!";
    }

    switch (dogProfile?.claimStatus) {
      // case "pending":
      //   return "Claim in progress";
      case "accepted":
        return "accepted!";
      default:
        return " ";
    }
  };

  // Then in your JSX:
  <Typography
    variant="h5"
    fontWeight="bold"
    sx={{
      color:
        dogProfile?.euthanasiaStatus === "pending"
          ? dogProfile?.claimStatus === "pending"
            ? COLORS.orange
            : COLORS.secondary
          : dogProfile?.euthanasiaStatus === "rescued"
          ? COLORS.primaryGreen
          : COLORS.secondary,
      animation:
        calculateCountdown(dogProfile) !== "Time expired"
          ? `${blinkAnimation} 1.5s ease-in-out infinite`
          : "none",
    }}
  >
    {getStatusText(dogProfile)}
  </Typography>;
  // --- DESKTOP ---

  const renderProfileContent = () => (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{ display: "flex", alignItems: "center",}}
        >
          {dogProfile?.images && dogProfile?.images[0] && (
            <Avatar
              src={dogProfile?.images[0]}
              sx={{ width: 100, height: 100, marginRight: "16px" }}
            />
          )}
          <Box sx={{ width: "100%" }}>
            <Typography variant="h4" fontWeight="bold">
              {dogProfile?.name}
              <Typography variant="h6" component="span">
                ({dogProfile?.gender})
              </Typography>
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {dogProfile?.breed} • {dogProfile?.age} • {dogProfile?.weight} lbs
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
          marginTop: "16px",
        }}
      >
        <Button
          onClick={() => {
            if (!user) {
              setGlobalState({
                ...globalState,
                showAuthModal: true,
              });
            } else {
              if (!user?.stripeCustomerId) {
                setGlobalState({
                  ...globalState,
                  showAddCardModal: true,
                  selectedDog: dogProfile,
                });
              } else {
                setGlobalState({
                  ...globalState,
                  showPledgeModal: true,
                  selectedDog: dogProfile,
                });
              }
            }
          }}
          variant="contained"
          sx={{
            backgroundColor: COLORS.primaryGreen,
            color: "#ffffff",
            fontWeight: "bold",
            padding: "8px 16px",
            borderRadius: "8px",
            flex: 1,
            marginRight: "8px",
            "&:hover": {
              backgroundColor: COLORS.darkGreen,
            },
          }}
        >
          PLEDGE MONEY
        </Button>
        <Button
          onClick={handleShare}
          variant="outlined"
          startIcon={<ShareOutlinedIcon />}
          sx={{
            color: COLORS.blue,
            borderColor: COLORS.blue,
            fontWeight: "bold",
            padding: "8px 16px",
            borderRadius: "8px",
            flex: 1,
            marginLeft: "8px",
            "&:hover": {
              backgroundColor: COLORS.lightBlue,
            },
          }}
        >
          SHARE ({dogProfile?.sharedCount || 3})
        </Button>
      </Box>

      <Typography variant="caption" color="text.secondary">
        BREED NOTES
      </Typography>
      <DescriptionTypography
        variant="body1"
        color="text.primary"
        style={{ marginBottom: "14px" }}
      >
        {dogProfile?.description}
      </DescriptionTypography>

      {dogProfile?.evaluation && (
        <Typography variant="caption" color="text.secondary">
          LAST EVALUATION
        </Typography>
      )}
      {dogProfile?.evaluation && (
        <Typography
          variant="body1"
          color="text.primary"
          sx={{
            WebkitLineClamp: showFullDescription ? "unset" : 3,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            marginBottom: "14px",
          }}
        >
          {dogProfile?.evaluation}
        </Typography>
      )}

      <TotalUsersContainer>
        <Box>
          <Typography variant="caption" color="text.secondary">
            {dogProfile?.daysAtShelter
              ? `${dogProfile?.daysAtShelter} DAYS AT SHELTER`
              : ""}
          </Typography>
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              color:
                dogProfile?.euthanasiaStatus === "pending"
                  ? dogProfile?.claimStatus === "pending"
                    ? COLORS.orange
                    : COLORS.secondary
                  : dogProfile?.euthanasiaStatus === "rescued"
                  ? COLORS.primaryGreen
                  : COLORS.secondary,
              animation:
                calculateCountdown(dogProfile) !== "Time expired"
                  ? `${blinkAnimation} 1.5s ease-in-out infinite`
                  : "none",
            }}
          >
            {getStatusText(dogProfile)}
          </Typography>
          {dogProfile?.euthanasiaStatus !== "rescued" ||
          dogProfile?.claimed !== "rescued" ? (
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{
                color:
                  dogProfile?.euthanasiaStatus === "pending"
                    ? COLORS.secondary
                    : dogProfile?.euthanasiaStatus === "rescued"
                    ? COLORS.primaryGreen
                    : COLORS.secondary,
                animation:
                  calculateCountdown(dogProfile) !== "Time expired"
                    ? `${blinkAnimation} 1.5s ease-in-out infinite`
                    : "none",
              }}
            >
              Kill Date:{" "}
              {dogProfile?.euthanasiaDate &&
                (() => {
                  const today = new Date();
                  const [year, month, day] = dogProfile.euthanasiaDate
                    .split("-")
                    .map(Number);
                  const killDate = new Date(Date.UTC(year, month - 1, day));

                  const tomorrow = new Date(today);
                  tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);

                  const formatDate = (date) =>
                    date.toLocaleDateString("en-US", {
                      timeZone: "UTC",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    });

                  if (
                    today.getUTCFullYear() === killDate.getUTCFullYear() &&
                    today.getUTCMonth() === killDate.getUTCMonth() &&
                    today.getUTCDate() === killDate.getUTCDate()
                  ) {
                    return `TODAY (${formatDate(killDate)})`;
                  } else if (
                    tomorrow.getUTCFullYear() === killDate.getUTCFullYear() &&
                    tomorrow.getUTCMonth() === killDate.getUTCMonth() &&
                    tomorrow.getUTCDate() === killDate.getUTCDate()
                  ) {
                    return `TOMORROW (${formatDate(killDate)})`;
                  }
                  return formatDate(killDate);
                })()}
            </Typography>
          ) : (
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{
                color:
                  dogProfile?.euthanasiaStatus === "pending"
                    ? COLORS.secondary
                    : dogProfile?.euthanasiaStatus === "rescued"
                    ? COLORS.primaryGreen
                    : COLORS.secondary,
              }}
            >
              Rescued Date:{" "}
              {dogProfile?.claimedAt &&
                new Date(dogProfile?.claimedAt).toLocaleDateString()}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Button
            disabled={
              dogProfile?.claimStatus === "pending" ||
              dogProfile?.claimStatus === "accepted"
            }
            onClick={() => {
              if (!user) {
                setGlobalState({
                  ...globalState,
                  showAuthModal: true,
                });
              } else if (
                !user?.rescueOrganization ||
                !user?.stripe_account_id
              ) {
                setGlobalState({
                  ...globalState,
                  showRescueDetailsModal: true,
                });
              } else {
                setGlobalState({
                  ...globalState,
                  showClaimFundsModal: true,
                  selectedDog: dogProfile,
                });
              }
            }}
            variant="text"
            sx={{
              fontSize: "12px",
              color: COLORS.lightGray4,
              fontWeight: "bold",
              alignItems: "center",
              border:
                dogProfile?.claimStatus === "pending" ||
                dogProfile?.claimStatus === "accepted"
                  ? `1px solid ${COLORS.primaryGreen}`
                  : `1px solid ${COLORS.lightGray4}`,
            }}
          >
            {dogProfile?.claimStatus === "pending"
              ? "CLAIM PENDING"
              : dogProfile?.claimStatus === "accepted"
              ? "CLAIMED"
              : "CLAIM FUNDS"}
          </Button>
          <Typography variant="caption" color="text.secondary">
            For Rescues
          </Typography>
        </Box>
      </TotalUsersContainer>
      <TotalUsersContainer>
        <Box>
          <Typography variant="caption" color="text.secondary">
            FOSTER STATUS
          </Typography>

          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              color: dogProfile?.activeFoster
                ? COLORS.primaryGreen
                : COLORS.blue,
            }}
          >
            {dogProfile?.activeFoster ? "With Foster" : "Needs Foster"}
          </Typography>

          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              color: dogProfile?.activeFoster
                ? COLORS.primaryGreen
                : COLORS.blue,
            }}
          >
            {dogProfile?.fosterApplications &&
            Object.values(dogProfile?.fosterApplications)?.length > 0
              ? `Pending Fosters: ${
                  Object.values(dogProfile?.fosterApplications)?.length
                }`
              : "Pending Fosters: 0"}
          </Typography>
        </Box>

        <Button
          onClick={handleFosterClick}
          variant="text"
          sx={{
            fontSize: "12px",
            marginBottom: "12px",
            color: COLORS.lightGray4,
            fontWeight: "bold",
            maxWidth: "185px",
            alignItems: "center",
            border: `1px solid ${COLORS.lightGray4}`,
          }}
        >
          FOSTER {dogProfile?.name}
        </Button>
      </TotalUsersContainer>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <LocationOnIcon sx={{ mr: 1, color: COLORS.primaryGreen }} />

        <Box
          sx={{
            mt: 4,
            mb: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            sx={{ display: "flex", alignItems: "center" }}
          >
            Shelter Information
          </Typography>
          <Typography variant="body1">
            {dogProfile?.shelter?.name || "Unknown Shelter"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {dogProfile?.shelter?.location || "Location not provided"}
          </Typography>
        </Box>
      </Box>

      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ marginTop: "24px", textAlign: "center" }}
      >
        97% of pledges go to the rescue org that saves {dogProfile?.name}. 2.9%
        payment processing fee. 0% platform fee.
      </Typography>

      <Divider sx={{ marginTop: "24px" }} />

      <TopUserContainer sx={{ marginTop: "24px", justifyContent: "center" }}>
        <UserInfo>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
              width: "100%",
            }}
          >
            <Box>
              <Typography variant="caption" color="text.secondary">
                TOTAL PLEDGED
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold">
                ${totalPledged.toFixed(2)}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="text.secondary">
                TOP PLEDGER
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold">
                {topPledger
                  ? topPledger?.userName
                    ? topPledger.userName
                    : topPledger.userEmail
                    ? topPledger.userEmail.slice(0, 5) + "..."
                    : "Anon"
                  : "N/A"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="text.secondary">
                PLEDGERS
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold">
                {pledgerCount}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="text.secondary">
                TOP CORP PLEDGER
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                sx={{ color: COLORS.lightGray4 }}
              >
                Coming soon
              </Typography>
            </Box>
          </Box>
        </UserInfo>
      </TopUserContainer>
    </>
  );

  const renderActivityContent = () => (
    <>
      <Typography
        variant="h6"
        sx={{ marginBottom: "16px", fontWeight: "bold" }}
      >
        Activity
      </Typography>
      <ActivityInputContainer>
        <ActivityTextField
          variant="outlined"
          placeholder="Add a new activity..."
          value={newActivity}
          onChange={(e) => setNewActivity(e.target.value)}
          multiline
          rows={3}
        />
        <ActionRow>
          <IconButton
            onClick={() => fileInputRef.current.click()}
            sx={{ color: COLORS.lightGray4 }}
          >
            <AddPhotoAlternateIcon />
          </IconButton>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImageUpload}
            accept="image/*"
            style={{ display: "none" }}
          />
          <SubmitButton
            variant="contained"
            endIcon={
              uploading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <SendIcon />
              )
            }
            onClick={handleSubmitActivity}
            disabled={!newActivity.trim() || uploading}
          >
            {uploading ? "Posting..." : "Post"}
          </SubmitButton>
        </ActionRow>
        {selectedImage && (
          <ImagePreview
            src={URL.createObjectURL(selectedImage)}
            alt="Selected"
          />
        )}
      </ActivityInputContainer>
      {Object.values(dogProfile?.posts || {}).map((post) => (
        <ActivityPost key={post.id}>
          <UserInfoContainer
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AvatarContainer>
                <NiceAvatar
                  style={{ width: "40px", height: "40px" }}
                  {...postAvatarConfigs[post.id]}
                />
              </AvatarContainer>
              <Typography variant="subtitle1">
                {post.username || "Anonymous"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <PostDate variant="body2">
                {new Date(post.date).toLocaleDateString()}
              </PostDate>
              <PostDate variant="body2">
                {new Date(post.date).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </PostDate>
            </Box>
          </UserInfoContainer>

          {post.images &&
            post.images.length > 0 &&
            (post.images.length === 1 ? (
              <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                <img
                  src={post.images[0]}
                  alt="Post image"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "300px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
              </Box>
            ) : (
              <ImageList cols={3} rowHeight={120} gap={8}>
                {post.images.map((img, index) => (
                  <ImageListItem key={index}>
                    <img
                      src={img}
                      alt={`Post image ${index + 1}`}
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "4px",
                      }}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            ))}

          <PostDescription variant="body1">{post.description}</PostDescription>

          <InteractionContainer>
            <InteractionItem>
              {/* <ChatBubbleOutline fontSize="small" color="action" />
              <InteractionTypography variant="body2">
                comments
              </InteractionTypography> */}
            </InteractionItem>
            <InteractionItem>
              <ShareOutlinedIcon fontSize="small" color="action" />
              <InteractionTypography variant="body2">
                {post.shares || 0} shares
              </InteractionTypography>
            </InteractionItem>
          </InteractionContainer>
        </ActivityPost>
      ))}
      {Object.keys(dogProfile?.posts || {}).length === 0 && (
        <Typography variant="body1" textAlign="center" color="text.secondary">
          No activity posts available.
        </Typography>
      )}
    </>
  );

  if (!isMobileView) {
    return (
      <PageContainer>
        <Header position="static" sx={{ paddingX: "5%" }}>
          <CustomToolbar />
        </Header>
        <ContentWrapper>
          <DesktopContainer>
            <LeftColumn>
              <Card>
                <ScrollableCardContent>
                  {renderProfileContent()}
                  {renderSimilarDogs()}
                </ScrollableCardContent>
              </Card>
            </LeftColumn>
            <RightColumn>
              <Card>
                <ScrollableCardContent>
                  {renderActivityContent()}
                </ScrollableCardContent>
              </Card>
            </RightColumn>
          </DesktopContainer>
        </ContentWrapper>

        {renderShareModal()}
        {renderEnterUserNameModal()}
        {renderFosterModal()}
        <Elements stripe={stripePromise}>
          {globalState.showPledgeModal && <PledgeModal />}
          {globalState.showAddCardModal && <AddCardModal />}
        </Elements>
        {globalState.showAuthModal && <AuthModal />}
        {globalState.showClaimFundsModal && <ClaimFundsModal />}
        {globalState?.showRescueDetailsModal && <RescueDetailsModal />}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={fosterSnackbarOpen}
          autoHideDuration={3000}
          onClose={() => setFosterSnackbarOpen(false)}
          message={`Foster application for ${dogProfile?.name} submitted successfully!`}
        />
        <Footer />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Header position="static">
        <CustomToolbar />
      </Header>
      <Card>
        <ScrollableCardContent>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              {dogProfile?.images && dogProfile?.images[0] && (
                <Avatar
                  src={dogProfile?.images[0]}
                  sx={{ width: 64, height: 64, marginRight: "16px" }}
                />
              )}
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    marginBottom: "8px",
                  }}
                >
                  <Typography variant="5" fontWeight="bold">
                    {dogProfile?.name}
                    {"  "}
                    <Typography variant="caption">
                      ({dogProfile?.gender})
                    </Typography>
                  </Typography>
                  {/* <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <WarningAmber
                      sx={{
                        fontSize: "16px",
                        marginRight: "8px",
                        color: COLORS.secondary,
                        animation:
                          calculateCountdown(dogProfile) !== "Time expired"
                            ? `${blinkAnimation} 1.5s ease-in-out infinite`
                            : "none",
                      }}
                    />
                    <Typography
                      variant="caption"
                      sx={{ color: "black", textAlign: "right" }}
                    >
                      {countdown}
                    </Typography>
                  </Box> */}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mr: 1 }}
                  >
                    {dogProfile?.breed}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mr: 1 }}
                  >
                    •
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mr: 1 }}
                  >
                    {dogProfile?.age}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mr: 1 }}
                  >
                    •
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mr: 1 }}
                  >
                    {dogProfile?.weight} lbs
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <TabContainer>
            <Tab
              key={`activity-${activeTab}`}
              onClick={() => setActiveTab("activity")}
              sx={{
                backgroundColor:
                  activeTab === "activity" ? COLORS.lightGray5 : "transparent",
                color: activeTab === "activity" ? "black" : "inherit",
                fontWeight: activeTab === "activity" ? "bold" : "normal",
              }}
            >
              Activity
            </Tab>

            <Tab
              key={`profile-${activeTab}`}
              onClick={() => setActiveTab("profile")}
              sx={{
                backgroundColor:
                  activeTab === "profile" ? COLORS.lightGray5 : "transparent",
                color: activeTab === "profile" ? "black" : "inherit",
                fontWeight: activeTab === "profile" ? "bold" : "normal",
              }}
            >
              Profile
            </Tab>
            {/* <Tab
                  onClick={() => setActiveTab("gifts")}
                  sx={{
                    backgroundColor:
                      activeTab === "gifts" ? COLORS.yellow : "transparent",
                    color: activeTab === "gifts" ? "black" : "inherit",
                    fontWeight: activeTab === "gifts" ? "bold" : "normal",
                  }}
                >
                  Gifts
                </Tab> */}
          </TabContainer>

          {/* SHARE POST  */}
          <ShareButton
            fullWidth
            variant="contained"
            startIcon={<ShareOutlinedIcon />}
            onClick={handleShare}
            size="large"
            sx={{
              width: "100%",
              backgroundColor: COLORS.blue,
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: "18px",
              padding: "16px",
              borderRadius: "12px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: COLORS.darkGreen,
                // transform: "translateX(-50%) translateY(-2px)",
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
              },
              "&:active": {
                // transform: "translateX(-50%) translateY(1px)",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              },
              "&:focus": {
                backgroundColor: COLORS.primaryGreen,
              },
            }}
          >
            SHARE ({dogProfile?.sharedCount || 3})
          </ShareButton>
          {renderShareModal()}

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            message="Link copied to clipboard!"
          />

          <Snackbar
            open={snackbar.open}
            autoHideDuration={3000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              severity={snackbar.severity}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>

          {activeTab === "profile" && (
            <>
              <Typography variant="caption" color="text.secondary">
                BREED NOTES:
              </Typography>
              <DescriptionTypography
                ref={descriptionRef}
                variant="body1"
                color="text.primary"
                sx={{
                  WebkitLineClamp: showFullDescription ? "unset" : 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  marginBottom: "14px",
                }}
              >
                {dogProfile?.description}
              </DescriptionTypography>

              {dogProfile?.evaluation && (
                <Typography variant="caption" color="text.secondary">
                  LAST EVALUATION:
                </Typography>
              )}
              {dogProfile?.evaluation && (
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{
                    WebkitLineClamp: showFullDescription ? "unset" : 3,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    marginBottom: "14px",
                  }}
                >
                  {dogProfile?.evaluation}
                </Typography>
              )}

              {!showFullDescription && (
                <ReadMoreButton
                  onClick={() => setShowFullDescription(true)}
                  color="primary"
                >
                  Read More
                </ReadMoreButton>
              )}
              {/* <ReadMoreButton
                onClick={() => setShowFullDescription(!showFullDescription)}
                color="primary"
              >
                {showFullDescription ? "Read Less" : "Read More"}
              </ReadMoreButton> */}

              <TotalUsersContainer>
                <Box>
                  {dogProfile && (
                    <Typography variant="caption" color="text.secondary">
                      {dogProfile?.daysAtShelter
                        ? `${dogProfile?.daysAtShelter} DAYS AT SHELTER`
                        : ""}
                    </Typography>
                  )}
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{
                      color:
                        dogProfile?.euthanasiaStatus === "pending"
                          ? dogProfile?.claimStatus === "pending"
                            ? COLORS.orange
                            : COLORS.secondary
                          : dogProfile?.euthanasiaStatus === "rescued"
                          ? COLORS.primaryGreen
                          : COLORS.secondary,
                      animation:
                        calculateCountdown(dogProfile) !== "Time expired"
                          ? `${blinkAnimation} 1.5s ease-in-out infinite`
                          : "none",
                    }}
                  >
                    {/* {dogProfile?.euthanasiaStatus === "pending" */}
                    {calculateCountdown(dogProfile) !== "Time expired"
                      ? dogProfile?.claimStatus === "pending"
                        ? "Claim in progress"
                        : dogProfile?.claimStatus === "accepted"
                        ? "Rescued!"
                        : "Needs rescue"
                      : dogProfile?.euthanasiaStatus === "rescued"
                      ? "Rescued"
                      : "Expired :("}
                  </Typography>
                  {(dogProfile &&
                    dogProfile?.euthanasiaDate &&
                    dogProfile?.euthanasiaStatus !== "rescued") ||
                  dogProfile?.claimed !== "rescued" ? (
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        color:
                          dogProfile?.euthanasiaStatus === "pending"
                            ? COLORS.secondary
                            : dogProfile?.euthanasiaStatus === "rescued"
                            ? COLORS.primaryGreen
                            : COLORS.secondary,
                        animation:
                          calculateCountdown(dogProfile) !== "Time expired"
                            ? `${blinkAnimation} 1.5s ease-in-out infinite`
                            : "none",
                      }}
                    >
                      Kill Date:{" "}
                      {dogProfile?.euthanasiaDate &&
                        (() => {
                          const today = new Date();
                          const [year, month, day] = dogProfile.euthanasiaDate
                            .split("-")
                            .map(Number);
                          const killDate = new Date(
                            Date.UTC(year, month - 1, day)
                          );

                          const tomorrow = new Date(today);
                          tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);

                          const formatDate = (date) =>
                            date.toLocaleDateString("en-US", {
                              timeZone: "UTC",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            });

                          if (
                            today.getUTCFullYear() ===
                              killDate.getUTCFullYear() &&
                            today.getUTCMonth() === killDate.getUTCMonth() &&
                            today.getUTCDate() === killDate.getUTCDate()
                          ) {
                            return `TODAY (${formatDate(killDate)})`;
                          } else if (
                            tomorrow.getUTCFullYear() ===
                              killDate.getUTCFullYear() &&
                            tomorrow.getUTCMonth() === killDate.getUTCMonth() &&
                            tomorrow.getUTCDate() === killDate.getUTCDate()
                          ) {
                            return `TOMORROW (${formatDate(killDate)})`;
                          }
                          return formatDate(killDate);
                        })()}
                    </Typography>
                  ) : (
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        color:
                          dogProfile?.euthanasiaStatus === "pending"
                            ? COLORS.secondary
                            : dogProfile?.euthanasiaStatus === "rescued"
                            ? COLORS.primaryGreen
                            : COLORS.secondary,
                      }}
                    >
                      Rescued Date:{" "}
                      {dogProfile?.claimedAt &&
                        new Date(dogProfile?.claimedAt).toLocaleDateString()}
                    </Typography>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    disabled={
                      dogProfile?.claimStatus === "pending" ||
                      dogProfile?.claimStatus === "accepted"
                    }
                    onClick={() => {
                      if (!user) {
                        // no user
                        setGlobalState({
                          ...globalState,
                          showAuthModal: true,
                        });
                      } else if (
                        !user?.rescueOrganization || // rescue
                        !user?.stripe_account_id // stripe connect vendor
                      ) {
                        // no rescue details
                        setGlobalState({
                          ...globalState,
                          showRescueDetailsModal: true,
                        });
                      } else {
                        // claim dog + funds
                        setGlobalState({
                          ...globalState,
                          showClaimFundsModal: true,
                          selectedDog: dogProfile,
                        });
                      }
                    }}
                    variant="text"
                    sx={{
                      fontSize: "12px",
                      color: COLORS.lightGray4,
                      fontWeight: "bold",
                      alignItems: "center",
                      border:
                        dogProfile?.claimStatus === "pending" ||
                        dogProfile?.claimStatus === "accepted"
                          ? `1px solid ${COLORS.primaryGreen}`
                          : `1px solid ${COLORS.lightGray4}`,
                    }}
                  >
                    {dogProfile?.claimStatus === "pending"
                      ? "CLAIM PENDING"
                      : dogProfile?.claimStatus === "accepted"
                      ? "CLAIMED"
                      : "CLAIM FUNDS"}
                  </Button>
                  <Typography variant="caption" color="text.secondary">
                    For Rescues
                  </Typography>
                </Box>
              </TotalUsersContainer>
              <TotalUsersContainer>
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    FOSTER STATUS
                  </Typography>

                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{
                      color: dogProfile?.activeFoster
                        ? COLORS.primaryGreen
                        : COLORS.blue,
                    }}
                  >
                    {/* {dogProfile?.euthanasiaStatus === "pending" */}
                    {dogProfile?.activeFoster ? "With Foster" : "Needs Foster"}
                  </Typography>

                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                      color: dogProfile?.activeFoster
                        ? COLORS.primaryGreen
                        : COLORS.blue,
                    }}
                  >
                    {dogProfile?.fosterApplications &&
                    Object.values(dogProfile?.fosterApplications)?.length > 0
                      ? `Pending Fosters: ${
                          Object.values(dogProfile?.fosterApplications)?.length
                        }`
                      : "Pending Fosters: 0"}
                  </Typography>
                </Box>

                <Button
                  onClick={handleFosterClick}
                  variant="text"
                  sx={{
                    fontSize: "12px",
                    marginBottom: "12px",
                    color: COLORS.lightGray4,
                    fontWeight: "bold",
                    maxWidth: "185px",
                    alignItems: "center",
                    border: `1px solid ${COLORS.lightGray4}`,
                  }}
                >
                  FOSTER {dogProfile?.name}
                </Button>
              </TotalUsersContainer>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <LocationOnIcon sx={{ mr: 1, color: COLORS.primaryGreen }} />

                <Box
                  sx={{
                    mt: 4,
                    mb: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    Shelter Information
                  </Typography>
                  <Typography variant="body1">
                    {dogProfile?.shelter?.name || "Unknown Shelter"}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {dogProfile?.shelter?.location || "Location not provided"}
                  </Typography>
                </Box>
              </Box>

              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginTop: "24px", textAlign: "center" }}
              >
                97% of pledges go to the rescue org that saves{" "}
                {dogProfile?.name}. 2.9% payment processing fee. 0% platform
                fee.
              </Typography>

              {/* <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginTop: "14px", textAlign: "center" }}
              >
                <strong>Proof of Rescue</strong> required to claim pledges.
              </Typography> */}

              <Divider sx={{ marginTop: "24px" }} />

              <TopUserContainer
                sx={{ marginTop: "24px", justifyContent: "center" }}
              >
                <UserInfo>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "16px",
                      width: "100%",
                    }}
                  >
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        TOTAL PLEDGED
                      </Typography>
                      <Typography variant="subtitle1" fontWeight="bold">
                        ${totalPledged.toFixed(2)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        TOP PLEDGER
                      </Typography>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {topPledger
                          ? topPledger?.userName
                            ? topPledger.userName
                            : topPledger.userEmail
                            ? topPledger.userEmail.slice(0, 5) + "..."
                            : "Anon"
                          : "N/A"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        PLEDGERS
                      </Typography>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {pledgerCount}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        TOP CORP PLEDGER
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{ color: COLORS.lightGray4 }}
                      >
                        Coming soon
                      </Typography>
                    </Box>
                  </Box>
                </UserInfo>
              </TopUserContainer>

             
            </>
          )}

          {activeTab === "activity" && (
            <>
              <ActivityInputContainer>
                <ActivityTextField
                  variant="outlined"
                  placeholder="Add a new activity..."
                  value={newActivity}
                  onChange={(e) => setNewActivity(e.target.value)}
                  multiline
                  rows={3}
                />
                <ActionRow>
                  <IconButton
                    onClick={() => fileInputRef.current.click()}
                    sx={{ color: COLORS.lightGray4 }}
                  >
                    <AddPhotoAlternateIcon />
                  </IconButton>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageUpload}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <SubmitButton
                    variant="contained"
                    endIcon={
                      uploading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <SendIcon />
                      )
                    }
                    onClick={handleSubmitActivity}
                    disabled={!newActivity.trim() || uploading}
                  >
                    {uploading ? "Posting..." : "Post"}
                  </SubmitButton>
                </ActionRow>
                {selectedImage && (
                  <ImagePreview
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected"
                  />
                )}
              </ActivityInputContainer>
              {Object.values(dogProfile?.posts || {}).map((post) => (
                <>
                  <ActivityPost key={post.id}>
                    <UserInfoContainer
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <AvatarContainer>
                          <NiceAvatar
                            style={{ width: "40px", height: "40px" }}
                            {...postAvatarConfigs[post.id]} // Use the memoized config for this post
                          />
                        </AvatarContainer>
                        <Typography variant="subtitle1">
                          {post.username || "Anonymous"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <PostDate variant="body2">
                          {new Date(post.date).toLocaleDateString()}
                        </PostDate>
                        <PostDate variant="body2">
                          {new Date(post.date).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </PostDate>
                      </Box>
                    </UserInfoContainer>

                    {post.images &&
                      post.images.length > 0 &&
                      (post.images.length === 1 ? (
                        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                          <img
                            src={post.images[0]}
                            alt="Post image"
                            loading="lazy"
                            style={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "300px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                        </Box>
                      ) : (
                        <ImageList cols={3} rowHeight={120} gap={8}>
                          {post.images.map((img, index) => (
                            <ImageListItem key={index}>
                              <img
                                src={img}
                                alt={`Post image ${index + 1}`}
                                loading="lazy"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "4px",
                                }}
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      ))}

                    <PostDescription variant="body1">
                      {post.description}
                    </PostDescription>

                    <InteractionContainer>
                      <InteractionItem>
                        {/* <ChatBubbleOutline fontSize="small" color="action" />
                        <InteractionTypography variant="body2">
                          comments
                        </InteractionTypography> */}
                      </InteractionItem>
                      <InteractionItem>
                        <ShareOutlinedIcon fontSize="small" color="action" />
                        <InteractionTypography variant="body2">
                          {post.shares || 0} shares
                        </InteractionTypography>
                      </InteractionItem>
                    </InteractionContainer>
                  </ActivityPost>
                </>
              ))}

              {Object.keys(dogProfile?.posts || {}).length === 0 && (
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="text.secondary"
                >
                  No activity posts available.
                </Typography>
              )}
            </>
          )}

           {/* ADD SIMILAR DOGS SECTION */}
           <Box sx={{ marginTop: 4 }}>
                  <Typography variant="caption" color="text.secondary">
                    OTHER DOGS NEEDING HELP
                  </Typography>
                  <Box sx={{ display: 'flex', overflowX: 'auto', marginTop: 2 }}>
                    {similarDogs.map((dog) => (
                      <Card
                        key={dog.id}
                        onClick={() => window.open(`/${dog.slug}`, '_blank')}
                        sx={{
                          minWidth: 160,
                          maxWidth: 200,
                          marginRight: 2,
                          '&:last-child': { marginRight: 0 },
                        }}
                      >
                        <CardMedia
                          component="img"
                          height="120"
                          image={dog.images?.[0] || "https://via.placeholder.com/300x225"}
                          alt={dog.name}
                        />
                        <CardContent sx={{ padding: 1 }}>
                          <Typography variant="subtitle2" component="div" sx={{ fontWeight: 'bold' }}>
                            {dog.name}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {dog.breed} • {dog.age}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              display: 'block',
                              mt: 0.5,
                              color: COLORS.secondary,
                              animation: calculateCountdown(dog) !== "Time expired"
                                ? `${blinkAnimation} 1.5s ease-in-out infinite`
                                : "none",
                            }}
                          >
                            Kill Date: {getKillDate(dog)}
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                </Box>
        </ScrollableCardContent>
      </Card>

      {/* ENTER YOUR USER NAME MODAL  */}
      {renderEnterUserNameModal()}

      <Button
        onClick={() => {
          if (!user) {
            // no user
            setGlobalState({
              ...globalState,
              showAuthModal: true,
            });
          } else {
            if (!user?.stripeCustomerId) {
              setGlobalState({
                ...globalState,
                showAddCardModal: true,
                selectedDog: dogProfile,
              });
            } else {
              // user
              setGlobalState({
                ...globalState,
                showPledgeModal: true,
                selectedDog: dogProfile,
              });
            }
          }
        }}
        variant="contained"
        size="large"
        sx={{
          position: "fixed",
          bottom: "24px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "90%",
          maxWidth: "600px",
          backgroundColor: COLORS.primaryGreen,
          color: "#ffffff",
          fontWeight: "bold",
          fontSize: "18px",
          padding: "16px",
          borderRadius: "12px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
          transition: "all 0.3s ease",
          zIndex: 1000,
          "&:hover": {
            backgroundColor: COLORS.darkGreen,
            transform: "translateX(-50%) translateY(-2px)",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
          },
          "&:active": {
            transform: "translateX(-50%) translateY(1px)",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          },
          "&:focus": {
            backgroundColor: COLORS.primaryGreen,
          },
        }}
      >
        PLEDGE MONEY
      </Button>
      <Elements stripe={stripePromise}>
        {globalState.showPledgeModal && <PledgeModal />}
        {globalState.showAddCardModal && <AddCardModal />}
      </Elements>
      {globalState.showAuthModal && <AuthModal />}
      {globalState.showClaimFundsModal && <ClaimFundsModal />}
      {globalState?.showRescueDetailsModal && <RescueDetailsModal />}
      {renderFosterModal()}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={fosterSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setFosterSnackbarOpen(false)}
        message={`Foster application for ${dogProfile?.name} submitted successfully!`}
      />
      <Footer />
    </PageContainer>
  );
};

export default DogProfilePage;
