export const COLORS = {
  // base colors
  primary: "#194868", // Dark Blue
  secondary: "#FF615F", // peach

  // colors
  primaryGreen: "#3CDBA0",
  black: "#1E1F20",
  white: "#FFFFFF",
  lightGray: "#ABAFB8",
  lightGray2: "#EFEFF0",
  lightGray3: "#D4D5D6",
  lightGray4: "#7D7E84",
  lightGray5: "#ffffff",
  lightGray6: "#f4f8f9",
  gray: "#BEC1D2",
  blue: "#42B0FF",
  red: "#ff4743",
  dodgerBlue: "#1E90FF",
  darkgreen: "#59990F",
  darkgray: "#898C95",
  // lightBlue: "#95A9B8",
  // darkBlue: "#003153",
  transparent: "transparent",

  //paws
  beige: "#fceee2",
  orange: "#ff6b00",
  orangeTwo: "#fdaa24",
  orangeThree: "#febf4a",
  lightBlue: "#07a8b2",
  darkBlue: "#00273a",
  yellow: "#ffde59"
};

// export default COLORS;
