import { COUNTRIES } from "./CONSTANTS";

// grams to lbs
export const gramsToLbs = (grams) => {
  const lbs = grams / 453.592; // 1 lb = 453.592 grams
  return lbs.toFixed(2); // Round to 2 decimal places
};

export const ozToLbs = (oz) => {
  return (oz / 16).toFixed(2); // 1 lb = 16 oz
};

// generate random unique id
export const guidGenerator = () => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

export const calculateCountdown = (dogProfile) => {
  const now = new Date();
  const euthanasiaDate = new Date(dogProfile?.euthanasiaDate + "T23:59:59");

  const difference = euthanasiaDate.getTime() - now.getTime();

  if (difference > 0) {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    let countdownString = "";
    if (days > 0) {
      countdownString += `${days}d `;
    }
    if (hours > 0 || days > 0) {
      countdownString += `${hours}h `;
    }
    if (minutes > 0 || hours > 0 || days > 0) {
      countdownString += `${minutes}m `;
    }
    countdownString += `${seconds}s`;

    return countdownString.trim();
  } else {
    return "Time expired";
  }
};

export const unprocessedPledges = (pledges) => {
  const uPledges = Object.values(pledges || {});
  return uPledges.filter((pledge) => pledge.status === "requires_captures");
};

export const getScoreColor = (score) => {
  if (score < 1 || score > 7) return "#808080"; // Gray for invalid scores
  if (score >= 1 && score < 2) return "#8B0000"; // Dark red for type 1
  if (score >= 2 && score < 3) return "#32CD32"; // Lime green for type 2 (ideal)
  if (score >= 3 && score < 4) return "#9ACD32"; // Yellow green for type 3
  if (score >= 4 && score < 5) return "#FFA500"; // Orange for type 4
  if (score >= 5 && score < 6) return "#FF6347"; // Tomato for type 5
  if (score >= 6 && score < 7) return "#FF4500"; // Orange red for type 6
  return "#8B0000"; // Dark red for type 7
};

export const getConsistencyCharacteristics = (level) => {
  if (level <= 25) {
    return [
      { emoji: "🪨", text: "Hard and dry" },
      { emoji: "🧱", text: "Difficult to pass" },
      { emoji: "🥜", text: "May resemble small pellets or pebbles" },
    ];
  } else if (level <= 50) {
    return [
      { emoji: "🍌", text: "Firm but soft" },
      { emoji: "✅", text: "Well-formed and easy to pass" },
      { emoji: "👍", text: "Ideal consistency" },
    ];
  } else if (level <= 75) {
    return [
      { emoji: "🍦", text: "Soft and moist" },
      { emoji: "💧", text: "Losing definition" },
      { emoji: "🧽", text: "May leave residue" },
    ];
  } else {
    return [
      { emoji: "💦", text: "Very loose and watery" },
      { emoji: "🥣", text: "No solid pieces" },
      { emoji: "🍯", text: "Resembles pudding or gravy" },
    ];
  }
};

// find state from location props
export const findState = (locProps) => {
  let USA = COUNTRIES.UNITED_STATES;
  const arrUSA = Object.keys(USA);
  let stateVar = null;
  locProps.map((locObj) => {
    for (let i = 0; i < arrUSA.length; i++) {
      if (locObj.long_name === arrUSA[i]) {
        stateVar = arrUSA[i];
      }
    }
  });
  return stateVar;
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result.toDateString();
};

// reorganizes / cleans the data set
export const enhanceContextData = (user) => {
  const userPreferences = user?.contextData
    ? Object.values(user?.contextData).map((elem) => {
        return elem[0];
      })
    : [];
  return userPreferences;
};

export const calculateSavings = (pricing) => {
  const weeklyPrice = pricing.find((plan) => plan.title === "Weekly").price;
  const yearlyPrice = pricing.find((plan) => plan.title === "Yearly").price;
  const weeksInYear = 52;

  const savingsPercentage =
    ((weeklyPrice * weeksInYear - yearlyPrice) / (weeklyPrice * weeksInYear)) *
    100;
  return Math.round(savingsPercentage);
};
