import React, { useContext, useState } from "react";
import {
  Modal,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { styled, useMediaQuery } from "@mui/system";
import { motion } from "framer-motion";
import GlobalStateContext from "../context/globalStateContext";
import { COLORS } from "../utils/COLORS";
import GoogleIcon from "@mui/icons-material/Google";
import { firebase, googleProvider } from "../firebaseConfig";
import { toast } from "react-simple-toasts";
import UserContext from "../context/userContext";

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: COLORS.lightGray6,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
  width: "90%",
  maxWidth: "400px",
  maxHeight: "80vh",
  overflowY: "auto",
  textAlign: "center",
}));

const GradientButton = styled(Button)({
  // background: `linear-gradient(45deg, ${COLORS.yellow} 30%, ${COLORS.orangeThree} 90%)`,
  backgroundColor: COLORS.primaryGreen,
  border: 0,
  color: COLORS.white,
  height: 48,
  padding: "0 30px",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
});

const GoogleButton = styled(Button)({
  backgroundColor: COLORS.white,
  color: COLORS.darkBlue,
  border: `1px solid ${COLORS.lightGray3}`,
  "&:hover": {
    backgroundColor: COLORS.lightGray2,
  },
});

const AuthModal = () => {
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [currentMode, setCurrentMode] = useState("createAccount");
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleClose = () =>
    setGlobalState({ ...globalState, showAuthModal: false });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (currentMode === "createAccount") {
        await handleCreateAccount();
      } else {
        await handleSignIn();
      }
      handleClose();
    } catch (error) {
      console.error("Error:", error);
      toast(error.message || "An error occurred. Please try again.");
    }
    setLoading(false);
  };

  const handleGoogleSignIn = async () => {
    setLoadingGoogle(true);
    try {
      await firebase.auth().signInWithPopup(googleProvider);
      toast("Successfully signed in with Google!");
      handleClose();
    } catch (error) {
      console.error("Error signing in with Google:", error);
      toast("Failed to sign in with Google. Please try again.");
    }
    setLoadingGoogle(false);
  };

  const handleCreateAccount = async () => {
    try {
      const userCredential = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;

      // Write user info to Realtime Database
      await firebase
        .database()
        .ref("users/" + user.uid)
        .set({
          email: user.email,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          uid: user.uid,
          username: username,
          // Add any other initial user data you want to store
        });

      setUser({
        email: user.email,
        username: username,
        createdAt: firebase.database.ServerValue.TIMESTAMP,
        uid: user.uid,
        // Add any other initial user data you want to store
      });

      toast("Account created successfully!");
    } catch (error) {
      console.error("Error creating account:", error);
      throw error; // Re-throw the error to be caught in handleSubmit
    }
  };

  const handleSignIn = async () => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      const user = firebase.auth().currentUser;
      if (!user?.uid) {
        toast("No user found");
        return;
      }

      const userData = await firebase
        .database()
        .ref("users/" + user.uid)
        .once("value");

      const userDataObj = userData.val();

      setUser(userDataObj);
      toast("Successfully signed in!");
    } catch (error) {
      console.error("Error signing in:", error);
      if (error.code === "auth/wrong-password") {
        toast("Incorrect password. Please try again.");
      } else {
        toast(
          error.message ||
            "An error occurred while signing in. Please try again."
        );
      }
      throw error; // Re-throw the error to be caught in handleSubmit
    }
  };

  return (
    <StyledModal
      open={globalState.showAuthModal}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
    >
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        exit={{ y: "100%" }}
        onClick={handleClose}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: isMobileView ? "flex-end" : "center", height: "100%" }}
      >
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <Typography variant="h5" gutterBottom sx={{ color: COLORS.darkBlue }}>
            {currentMode === "createAccount" ? "Create an account" : "Sign in"}
          </Typography>
          <form onSubmit={handleSubmit}>
            {currentMode === "createAccount" && (
              <TextField
                fullWidth
                margin="normal"
                label="Username"
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            )}
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              type="password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <GradientButton
              type="submit"
              fullWidth
              sx={{ mt: 2 }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : currentMode === "createAccount" ? (
                "Lets get started"
              ) : (
                "Sign in"
              )}
            </GradientButton>
          </form>

          {/* <GoogleButton
            sx={{ mt: 2 }}
            startIcon={<GoogleIcon src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" />}
            onClick={handleGoogleSignIn}
            fullWidth
            disabled={loadingGoogle}
          >
            {loadingGoogle ? <CircularProgress size={24} color="inherit" /> : 'Sign in with Google'}
          </GoogleButton> */}
          <Typography variant="body2" sx={{ my: 2 }}>
            {currentMode === "createAccount" ? "Already have an account?" : ""}
            <Button
              onClick={() =>
                setCurrentMode(
                  currentMode === "createAccount" ? "signIn" : "createAccount"
                )
              }
            >
              {currentMode === "createAccount" ? "Login" : "Create an account"}
            </Button>
          </Typography>
        </ModalContent>
      </motion.div>
    </StyledModal>
  );
};

export default AuthModal;
