import React, { useEffect, useState } from "react";
import { Typography, Container, Box, Link } from "@mui/material";
import { styled } from "@mui/system";
import { COLORS } from "../utils/COLORS";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import CustomToolbar from "../components/CustomToolbar";
import Footer from "../components/Footer";
import discordLogo from "../assets/discord-logo.png";

const PageContainer = styled(Box)({
  paddingBottom: "50px",
});

const ContentContainer = styled(Container)({
  maxWidth: "800px",
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    color: COLORS.darkBlue,
    marginTop: "2rem",
    marginBottom: "1rem",
  },
  "& p": {
    marginBottom: "1rem",
  },
  "& ul": {
    marginBottom: "1rem",
    paddingLeft: "2rem",
  },
});

const MissionPage = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const [buttonWidth, setButtonWidth] = useState("auto");

  useEffect(() => {
    const updateButtonWidth = () => {
      setButtonWidth(isMobileView ? "80%" : "auto");
    };

    updateButtonWidth();
    window.addEventListener("resize", updateButtonWidth);
    return () => window.removeEventListener("resize", updateButtonWidth);
  }, [isMobileView]);

  return (
    <Box
      sx={{
        backgroundColor: COLORS.white,
        minHeight: "100vh",
        overflowX: "hidden",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
      }}
    >
      <PageContainer>
        <Box
          sx={{
            paddingLeft: isMobileView ? 0 : "5rem",
            paddingRight: isMobileView ? "1rem" : "5rem",
          }}
        >
          <CustomToolbar />
        </Box>
        <ContentContainer>
          <Typography variant="h1">Our mission</Typography>
          <Typography variant="h6">
            Gaming as a way of ending dog euthanasias
          </Typography>

          <Typography variant="h4">
            End Dog Euthanasias In 3 Years (October 2027)
          </Typography>

          <Typography variant="h6">TL;DR</Typography>
          <Typography paragraph>
            We can end dog euthanasias by decoupling the financial & time cost
            of adopting a dog.
          </Typography>
          <Typography paragraph>
            Through a gamified virtual pet ownership app, users can 'virtually coparent' and be a part of
            a dog's recovery & comeback story post kill-shelter rescue. Virtually sponsor unlimited dogs, providing essential
            recurring income to rescue organizations to save more dogs.
          </Typography>

          <hr />

          <Typography variant="h4">By the numbers:</Typography>
          <ul>
            <li>359,000 dogs euthanized in 2023, marking a five-year peak.</li>
            <li>330,000 cats euthanized in the same period.</li>
            <li>
              6.5 million animals entered U.S. shelters in 2023, leading to
              overcrowding.
            </li>
            <li>Only 25% of dogs are adopted from shelters.</li>
            <li>
              4.8 million cats and dogs were adopted in 2023, not enough to
              counter intake.
            </li>
            <li>900,000 additional animals in shelters since 2021.</li>
            <li>
              Pandemic-related suspension of spay and neuter efforts worsened
              overpopulation.
            </li>
          </ul>

          <Typography variant="h4">Top 10 Kill Shelters USA</Typography>
          <ul>
            <li>
              CA - LA County Department of Animal Care and Control - Est 15,000+
              dogs annually
            </li>
            <li>
              TX - Harris County Animal Shelter - Est 12,000+ dogs annually
            </li>
            <li>
              AZ - Maricopa County Animal Care & Control - Est 10,000+ dogs
              annually
            </li>
            <li>
              FL - Miami-Dade Animal Services / Estimated 8,000+ dogs annually
            </li>
            <li>TX - Dallas Animal Services- Est 7,000+ dogs annually</li>

            <li>
              NC - Charlotte Mecklenburg Animal Care & Control - Est 6,500+ dogs
              annually
            </li>
            <li>
              CA - San Bernardino County Animal Care & Control - Est 6,000+ dogs
              annually
            </li>
            <li>
              GA - Fulton County Animal Services - Est 5,500+ dogs annually
            </li>
            <li>
              NV - Clark County Animal Control (Las Vegas) - Est 5,000+ dogs
              annually
            </li>
            <li>
              TX - San Antonio Animal Care Services - Est 4,800+ dogs annually
            </li>
          </ul>

          <Typography variant="h4">Population growth</Typography>
          <ul>
            <li>
              Average litter size: 5-8 for small dogs, 8-12 for large dogs
            </li>
            <li>On average, a household adopts 1 dog</li>
            <li>
              46% of US households have at least 1 dog (2nd highest globally)
            </li>
            <li>More breeders are breeding more litters</li>
          </ul>

          <Typography variant="h4">The Result</Typography>
          <ul>
            <li>Way more dogs are being born than adopted.</li>
            <li>Increasing number of dogs ending up in kill-shelters.</li>
          </ul>

          <hr />

          <Typography variant="h4">HYPOTHETICAL...</Typography>
          <ul>
            <li>
              What happens when rescue orgs generate monthly income from kill
              shelter dogs they save?
            </li>
            <li>
              What happens when rescue organizations made more money the longer
              dogs stayed with them?
            </li>
            <li>
              What happens when rescue organizations don't entirely depend on
              physical adoptions?
            </li>
          </ul>

          <Typography variant="h4">UNDERSTANDING THE PROBLEM'S PAST</Typography>
          <Typography paragraph>
            As more breeders serve more uneducated new pet owners, each new dog
            momma is having liters of 8-12 puppies.
          </Typography>
          <Typography paragraph>
            Growth in adoption rate is maxed out VS litters of puppies from breeders grows.
          </Typography>
          <Typography paragraph>
            The US has done spectacular job in advocacy - 2nd highest country in
            household pet ownership 46%.
          </Typography>
        

          <Typography variant="h4">UNDERSTANDING THE PROBLEM TODAY</Typography>
          <Typography paragraph>
            In 2020, a temporary freeze on spay and neuter efforts due to Covid
            led to an increase in litters.
          </Typography>
          <Typography paragraph>
            That has led to a 5 year high of euthanasia 2024.
          </Typography>
          <Typography paragraph>
            Comparably adoption rates have slowed due to less time & disposable
            income.
          </Typography>
          <Typography paragraph>
            46% of households with atleast 1 dog. 2nd highest country next to
            Argentina (66%).
          </Typography>
          
          <Typography paragraph>
            Thus it feels like a losing battle every week regardless of how much we advocate.
          </Typography>

          <Typography variant="h4">OUR SOLUTION</Typography>
          <Typography paragraph>
            How can we flip the current model on it's head?
          </Typography>
          <Typography paragraph>
            In simple terms, how can we classify each dog as an asset upon arrival not a
            liability to a rescue?
          </Typography>
          <Typography paragraph>
            An asset generate a net positive monthly income.
          </Typography>
          <Typography paragraph>
            What happens when you decouple the financial and time commitment of
            adopting a dog?
          </Typography>
          <Typography paragraph>
            A community of 'virtual parents' will provide the financial
            care for the dog in return for exclusive updates, interactive fun, and being a part of the dog's recovery & comeback story.
          </Typography>
          <Typography paragraph>
            The rescue organization provides the time & care.
          </Typography>
          <Typography paragraph>
            But before rolling that out, lets discuss the precursor.
          </Typography>

          <Typography variant="h5">Here's the 2 stages:</Typography>
          <Typography paragraph>
            a) Fixing the current pledging system
          </Typography>
          <Typography paragraph>b) Real Virtual Pet Ownership</Typography>

          <Typography variant="h5">Stage 1 - Fixing Pledging System</Typography>
          <Typography paragraph>
            a) Issue 1: Currently, rescues have to DM users across Reddit, FB,
            TikTok to collect pledges.
          </Typography>
          <Typography paragraph>
            Resulting in only less than 50% of pledges being collected.
          </Typography>
          <Typography paragraph>
            Causing uncertainty, fear, doubt in being able to recoup the funds
            to save the dog.
          </Typography>
          <Typography paragraph>
            Costing rescues additional time & resources.
          </Typography>
          <Typography paragraph>
            b) Issue 2: Donors have no way of being updated on the welfare of
            the dog they save.
          </Typography>
          <Typography paragraph>
            These two issues are solved in the version 1 of paws.lol
          </Typography>
          <Typography paragraph>Here's how it works:</Typography>
          <ol>
            <li>
              User first makes a pledge for a dog
              <ul>
                <li>
                  pledges are initially authorized but not immediately charged.
                </li>
              </ul>
            </li>
            <li>
              Rescue orgs saves the dog & uploads a Proof of Rescue
              <ul>
                <li>
                  rescues upload a photo of the dog with a paper bearing the
                  rescue organization's name and date, similar to Reddit
                  verifications. After manual approval, the donor is charged.
                </li>
              </ul>
            </li>
            <li>
              Funds transfered & donor notified
              <ul>
                <li>
                  upon charge, the donor is notified, and the full amount is
                  instantly transferred to the rescue organization Stripe
                  account.
                </li>
              </ul>
            </li>
          </ol>
          <Typography paragraph>
            Overall - although in itself it will not get us to 0 euthanasias,
            this will be the first time in history that rescue organizations can
            raise 100% of pledges before rescuing the dog.
          </Typography>
          <Typography paragraph>
            And it's laying the ground work for stage 2, where we'll continue to
            cement this economic model further.
          </Typography>

          <Typography variant="h5">
            Stage 2 - Real Virtual Pet Ownership
          </Typography>
          <Typography paragraph>
            Virtual pets have been a phenomena since the 90s..
          </Typography>
          <ul>
            <li>Tamagotchi Classic: Generated $10 billion+ (Launched 1996)</li>
            <li>My Talking Tom: Generated $1 billion+ (Launched 2013)</li>
            <li>Neopets: $160 million valuation. (Launched 1999)</li>
            <li>Pou: Generated Millions+ (Launched 2012)</li>
            <li>Pet Society: Generated Millions (Launched 2008)</li>
            <li>Pengu app: Currently generating millions (Launched 2024)</li>
          </ul>
          <Typography paragraph>
            Some physical items, some apps, have one time in common.
          </Typography>
          <Typography paragraph>
            Virtual pets are popular for providing interactive companionship and
            responsibility without the time and effort of a real pet.
          </Typography>
          <Typography paragraph>
            They also provide a way to be part of a pet's journey.
          </Typography>
          <Typography paragraph>
            In our case of kill shelter dogs, their journey story is the main
            reason people are drawn to them.
          </Typography>
          <Typography paragraph>
            But oftentimes, the uncertainty fear and doubt of adopting the wrong
            dog is too high.
          </Typography>
          <Typography paragraph>
            Interactive fun can be a great way to lower the risk of adopting the
            wrong dog.
          </Typography>
          <Typography paragraph>Interactive fun includes:</Typography>
          <ul>
            <li>24/7 kennel cams / live stream</li>
            <li>pov walk cam / live stream</li>
            <li>ability to give treats virtually</li>
            <li>ability to request walks / care</li>
            <li>
              ability to request specific training (i.e. crate training, house
              training)
            </li>
            <li>150+ more</li>
          </ul>
          <Typography paragraph>
            Virtual pet owners can also sponsor specific dogs to receive
            specific attention & care.
          </Typography>
          <Typography paragraph>
            A multitude of ways to improve the dog's adoption odds will also be
            included.
          </Typography>
          <Typography paragraph>
            Such things as virtual owners being able to sponsor dogs for
            specific training.
          </Typography>
          <Typography paragraph>
            Use cases for dogs in medical training is tremendous, such as
            training dogs to early detect hypoglecmia in type 2 diabetics.
            Multitude of other medical conditions.
          </Typography>
          <Typography paragraph>
            No to mention, the basics of obedience training, socialization, and
            other skills training.
          </Typography>
          <Typography paragraph>
            All this will build a special bond between virtual owners + their
            virtual pets.
          </Typography>
          <Typography paragraph>
            When you consider doing this for a local dog, you have the added
            benefit of being able to visit the dog in person, potentially even
            adopt it after you've built a strong bond.
          </Typography>
          <Typography paragraph>
            For existing dog owners, virtually adopting a dog locally can mean
            socialization & friends for your dog in a controlled environment.
          </Typography>
          <Typography paragraph>
            Specialized rescue organizations can also start-up focused on
            specific training types.
          </Typography>
          <Typography paragraph>
            We can form a network of rescues & fosters that can efficiently
            collectively work together.
          </Typography>

          <hr />

          {/* <Typography variant="h4">
           ACTION PLAN
          </Typography> */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
              padding: "10px 0",
              backgroundColor: "transparent",
            }}
          >
            <Button
              variant="contained"
              onClick={() =>
                window.open("https://discord.gg/GfF4Wjch", "_blank")
              }
              startIcon={
                <img
                  src={discordLogo}
                  alt="Discord Logo"
                  style={{ width: 24, height: 24 }}
                />
              }
              sx={{
                backgroundColor: "white",
                color: "#5865F2",
                fontWeight: "bold",
                textTransform: "none",
                border: "1px solid #5865F2",
                borderRadius: "28px",
                fontSize: "16px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                  transform: "translateY(-2px)",
                  boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
                },
                width: buttonWidth,
                maxWidth: "300px",
              }}
            >
              Join The Community
            </Button>
          </Box>
          <Typography sx={{ p: 0, mt: 0, fontSize: "15px", pt: 2 }}>
            Anyone can add a dog. Any rescue can claim all pledges after
            uploading Proof of Rescue.
          </Typography>

          <Typography sx={{ p: 0, mt: 0, fontSize: "20px" }}>
            Looking for:
          </Typography>
          <Typography sx={{ p: 0, mt: 0, fontSize: "14px" }}>
            🌟🌟🌟 Content creators in San Antonio - for creating TikTok videos to
            give more visibility to kill shelter dogs (paid per video)
          </Typography>


        <Box sx={{ display: "flex", my: 4 }}>
          <Button
            variant="contained"
            onClick={() =>
              window.open("https://twitter.com/sir_captcha", "_blank")
            }
            startIcon={
              <img
                src={require("../assets/face.jpg")}
                alt="Twitter Logo"
                style={{ width: 24, height: 24, borderRadius: "50%" }}
              />
            }
            sx={{
              backgroundColor: "white",
              color: "#1DA1F2",
              fontWeight: "bold",
              textTransform: "none",
              border: "1px solid #1DA1F2",
              borderRadius: "28px",
              fontSize: "16px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "#f0f0f0",
                transform: "translateY(-2px)",
                boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
              },
              width: buttonWidth,
              maxWidth: "300px",
            }}
          >
            Joy Captcha
          </Button>
        </Box>
        </ContentContainer>
      </PageContainer>

      <Footer />
    </Box>
  );
};

export default MissionPage;
