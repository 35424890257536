import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Typography,
  Paper,
  Chip,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AppBar,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import CustomToolbar from "../components/CustomToolbar";
import { COLORS } from "../utils/COLORS";
import { database } from "../firebaseConfig";
import UserContext from "../context/userContext";
import { guidGenerator } from "../utils/HELPER_FUNCTIONS";
import GlobalStateContext from "../context/globalStateContext";
import { ref, get } from "firebase/database";
import { useNavigate } from "react-router-dom";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { onValue, off } from "firebase/database";
import { storage } from "../firebaseConfig";
import { set } from "firebase/database";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

const PageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  justifyContent: "flex-start",
  alignItems: "center",
  backgroundColor: "#ffffff",
  boxSizing: "border-box", // Add this line
}));

const Header = styled(AppBar)({
  backgroundColor: COLORS.yellow,
  boxShadow: "none",
});

const ageRanges = [
  "0-6 months",
  "6-12 months",
  "1 year old",
  "2 years old",
  "3 years old",
  "4 years old",
  "5 years old",
  "6 years old",
  "7 years old",
  "8 years old",
  "9 years old",
  "10 years old",
  "11 years old",
  "12 years old",
  "13 years old",
  "14 years old",
  "15 years old",
  "16 years old",
  "17 years old",
  "18 years old",
  "19 years old",
  "20 years old",
];

const AddAnimalPage = () => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const { user, setUserContext } = useContext(UserContext);
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);

  const [animalData, setAnimalData] = useState({
    // Required fields
    name: "",
    age: "",
    gender: "",
    weight: "",
    breed: "",
    description: "",
    size: "",
    evaluation: "",
    shelterId: "",
    intakeDate: "",
    spayedNeutered: false,
    vaccinated: false,
    microchipped: false,
    animalId: "",

    // Platform fields
    color: "",
    euthanasiaRisk: true,
    euthanasiaDate: "",
    reasonForRisk: "",
    healthIssues: [],

    // Shelter info
    contactPhone: "",
    contactName: "",
  });

  const [openShelterModal, setOpenShelterModal] = useState(false);
  const [newShelter, setNewShelter] = useState({ name: "", location: "" });
  const [shelters, setShelters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const sheltersRef = ref(database, "availableShelters");

    const fetchShelters = onValue(
      sheltersRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const shelterList = Object.entries(data).map(([id, shelter]) => ({
            id,
            ...shelter,
          }));
          setShelters(shelterList);
        }
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching shelters:", error);
        setIsLoading(false);
      }
    );

    // Cleanup function
    return () => off(sheltersRef, "value", fetchShelters);
  }, []);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const removeImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleShelterChange = (event) => {
    const selectedShelterId = event.target.value;
    if (selectedShelterId === "add") {
      setOpenShelterModal(true);
    } else {
      setAnimalData((prevData) => ({
        ...prevData,
        shelterId: selectedShelterId,
      }));
    }
  };

  const handleNewShelterChange = (event) => {
    const { name, value } = event.target;
    setNewShelter((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddAnimal = async () => {
    if (!user) {
      setGlobalState({
        ...globalState,
        showAuthModal: true,
      });
      return;
    }

    // Basic form validation
    if (!animalData.name || !animalData.animalId || !animalData.shelterId) {
      alert("Please fill out all required fields");
      return;
    }

    setIsLoading(true);

    try {
      const newAnimalId = guidGenerator();
      const animalIdRef = ref(database, `takenSlugs/${animalData.animalId}`);
      const snapshot = await get(animalIdRef);
      const animalIdExists = snapshot.exists();

      if (animalIdExists) {
        alert("Animal ID already exists");
        navigate(`/${animalIdExists?.slug}`);
        return;
      }

      const slug = encodeURIComponent(
        animalData.name
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-")
          .replace(/^-+|-+$/g, "")
      );

      // Upload images and get their URLs
      const imageUrls = await Promise.all(
        images.map(async (image) => {
          const storageRef = storage.ref(
            `animal_images/${newAnimalId}/${image.name}`
          );
          const uploadTask = await storageRef.put(image);
          return await uploadTask.ref.getDownloadURL();
        })
      );

      const newAnimalData = {
        ...animalData,
        id: newAnimalId,
        createdAt: new Date().toISOString(),
        createdBy: user.uid,
        images: imageUrls,
        slug: slug,
      };

      // Save the new dog data to the database
      await database.ref(`availablePets/${slug}`).set(newAnimalData);

      // Save to taken slugs
      await database.ref(`takenSlugs/${newAnimalId}`).set({
        slug: slug,
      });

      // Clear the form
      setAnimalData({
        name: "",
        age: "",
        gender: "",
        weight: "",
        breed: "",
        description: "",
        size: "",
        evaluation: "",
        shelterId: "",
        intakeDate: "",
        spayedNeutered: false,
        vaccinated: false,
        microchipped: false,
        animalId: "",
        color: "",
        euthanasiaRisk: false,
        euthanasiaDate: "",
        reasonForRisk: "",
        healthIssues: [],
        contactPhone: "",
        contactName: "",
        slug: "",
      });
      setImages([]);

      // Show a success message
      alert("Dog added successfully!");

      // Navigate to the new dog's profile page
      navigate(`/${slug}`);
    } catch (error) {
      console.error("Error adding new dog:", error);
      alert("An error occurred while adding the new dog. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddShelter = async () => {
    try {
      const newShelterObj = {
        id: guidGenerator(),
        name: newShelter.name,
        location: newShelter.location,
        createdAt: new Date().toISOString(),
        createdBy: user?.uid,
      };

      const newShelterRef = database.ref(
        `availableShelters/${newShelterObj.id}`
      );

      // Add the new shelter data
      await newShelterRef.set(newShelterObj);

      setShelters((prev) => [...prev, newShelterObj]);

      setAnimalData((prevData) => ({
        ...prevData,
        shelterName: newShelter.name,
        shelterLocation: newShelter.location,
      }));

      // Close the modal
      setOpenShelterModal(false);

      // Clear the new shelter form
      setNewShelter({ name: "", location: "" });

      console.log("New shelter added successfully");
    } catch (error) {
      console.error("Error adding new shelter:", error);
      alert(
        "An error occurred while adding the new shelter. Please try again."
      );
    }
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setAnimalData((prevData) => ({
      ...prevData,
      [name]: event.target.type === "checkbox" ? checked : value,
    }));
  };

  const handleHealthIssuesChange = (event) => {
    const { value } = event.target;
    setAnimalData((prevData) => ({
      ...prevData,
      healthIssues: value.split(",").map((issue) => issue.trim()),
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(animalData);
    // Here you would typically send the data to your backend
  };

  return (
    <PageContainer>
      <Header position="static">
        <CustomToolbar />
      </Header>
      <Typography sx={{ fontSize: "24px", fontWeight: "bold", mt: 2 }}>
        Add New Animal
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            mt: 3,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            paddingBottom: "100px",
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<AddPhotoAlternateIcon />}
                >
                  Add Images
                </Button>
              </label>
            </Grid>

            {images.length > 0 && (
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {images.map((image, index) => (
                    <Box key={index} position="relative">
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Preview ${index}`}
                        style={{ width: 100, height: 100, objectFit: "cover" }}
                      />
                      <IconButton
                        size="small"
                        onClick={() => removeImage(index)}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          background: "white",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}
            {/* Required Fields */}
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Name"
                name="name"
                value={animalData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Animal ID"
                name="animalId"
                value={animalData.animalId}
                onChange={handleChange}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Age"
                name="age"
                value={animalData.age}
                onChange={handleChange}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Age</InputLabel>
                <Select
                  name="age"
                  value={animalData.age}
                  onChange={handleChange}
                  label="Age"
                >
                  {ageRanges.map((range) => (
                    <MenuItem key={range} value={range}>
                      {range}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Gender</InputLabel>
                <Select
                  name="gender"
                  value={animalData.gender}
                  onChange={handleChange}
                  label="Gender"
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Weight (lbs)"
                name="weight"
                type="number"
                value={animalData.weight}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Breed"
                name="breed"
                value={animalData.breed}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Size</InputLabel>
                <Select
                  name="size"
                  value={animalData.size}
                  onChange={handleChange}
                  label="Size"
                >
                  <MenuItem value="Small">Small</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Large">Large</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                multiline
                rows={4}
                label="History"
                name="description"
                value={animalData.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                multiline
                rows={4}
                label="Evaluation"
                name="evaluation"
                value={animalData.evaluation}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Shelter</InputLabel>
                <Select
                  name="shelterId"
                  value={animalData.shelterId}
                  onChange={handleShelterChange}
                  label="Shelter"
                >
                  {shelters.map((shelter) => (
                    <MenuItem key={shelter.id} value={shelter.id}>
                      {shelter.name}
                    </MenuItem>
                  ))}
                  <MenuItem value="add">
                    <em>Add a Shelter</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Intake Date"
                name="intakeDate"
                type="date"
                value={animalData.intakeDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            {/* Platform Fields */}
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={true}
                    checked={animalData.euthanasiaRisk}
                    onChange={handleChange}
                    name="euthanasiaRisk"
                  />
                }
                label="Euthanasia Risk"
              />
            </Grid>
            {animalData.euthanasiaRisk && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Euthanasia Date"
                    name="euthanasiaDate"
                    type="date"
                    value={animalData.euthanasiaDate}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Reason for Euthanasia</InputLabel>
                    <Select
                      name="reasonForRisk"
                      value={animalData.reasonForRisk}
                      onChange={handleChange}
                      label="Reason for Euthanasia"
                    >
                      <MenuItem value="Kennel capacity">
                        Kennel capacity
                      </MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Health Issues (comma-separated)"
                name="healthIssues"
                value={animalData.healthIssues.join(", ")}
                onChange={handleHealthIssuesChange}
              />
            </Grid>

            {/* Shelter Info */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Contact Name"
                name="contactName"
                value={animalData.contactName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Contact Phone"
                name="contactPhone"
                value={animalData.contactPhone}
                onChange={handleChange}
              />
            </Grid>

            {/* Checkboxes */}
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={animalData.spayedNeutered}
                      onChange={handleChange}
                      name="spayedNeutered"
                    />
                  }
                  label="Spayed/Neutered"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={animalData.vaccinated}
                      onChange={handleChange}
                      name="vaccinated"
                    />
                  }
                  label="Vaccinated"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={animalData.microchipped}
                      onChange={handleChange}
                      name="microchipped"
                    />
                  }
                  label="Microchipped"
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={() => {
                  if (!user) {
                    // no user
                    setGlobalState({
                      ...globalState,
                      showAuthModal: true,
                    });
                  } else {
                    handleAddAnimal();
                  }
                }}
                variant="contained"
                size="large"
                disabled={isLoading}
                sx={{
                  position: "fixed",
                  bottom: "24px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "90%",
                  maxWidth: "600px",
                  backgroundColor: COLORS.primaryGreen,
                  color: "#ffffff",
                  fontWeight: "bold",
                  fontSize: "18px",
                  padding: "16px",
                  borderRadius: "12px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
                  transition: "all 0.3s ease",
                  zIndex: 1000,
                  "&:hover": {
                    backgroundColor: COLORS.darkGreen,
                    transform: "translateX(-50%) translateY(-2px)",
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                  },
                  "&:active": {
                    transform: "translateX(-50%) translateY(1px)",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  },
                  "&:focus": {
                    backgroundColor: COLORS.primaryGreen,
                  },
                }}
              >
                {isLoading ? <CircularProgress /> : "Add Dog"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>

      <Dialog
        open={openShelterModal}
        onClose={() => setOpenShelterModal(false)}
      >
        <DialogTitle>Add New Shelter</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Shelter Name"
            type="text"
            fullWidth
            variant="standard"
            value={newShelter.name}
            onChange={handleNewShelterChange}
          />
          <TextField
            margin="dense"
            name="location"
            label="Shelter Location"
            type="text"
            fullWidth
            variant="standard"
            value={newShelter.location}
            onChange={handleNewShelterChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenShelterModal(false)}>Cancel</Button>
          <Button onClick={handleAddShelter}>Add</Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default AddAnimalPage;
