import React, { useState, useMemo, useContext } from "react";
import {
  Toolbar,
  Box,
  Typography,
  Popover,
  MenuItem,
  Button,
} from "@mui/material";
import LogoImgShort from "../assets/logo_short.png";
import styled from "styled-components";
import Avatar, { genConfig } from "react-nice-avatar";
import { firebase } from "../firebaseConfig";
import { toast } from "react-simple-toasts";
import UserContext from "../context/userContext";
import GetAppIcon from "@mui/icons-material/GetApp";
import { COLORS } from "../utils/COLORS";
import AppleIcon from "@mui/icons-material/Apple";
import AddIcon from "@mui/icons-material/Add";
import GlobalStateContext from "../context/globalStateContext";

const Logo = styled("img")({
  width: "40px",
  cursor: "pointer",
  marginRight: "10px",
});

const AvatarContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginLeft: "16px",
  cursor: "pointer",
});

const CustomToolbar = () => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const { user, setUser } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const avatarConfig = useMemo(() => genConfig(), []);
  const [isMobileView, setIsMobileView] = React.useState(
    window.innerWidth < 600
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      toast("Successfully logged out!");
      setUser(null);
      // You might want to redirect the user or update the app state here
    } catch (error) {
      console.error("Error logging out:", error);
      toast("Failed to log out. Please try again.");
    }
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <Toolbar
      sx={{
        justifyContent: "space-between",
        width: isMobileView ? "auto" : "90%",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Logo
          src={LogoImgShort}
          alt="Logo"
          onClick={() => {
            window.location.href = "/";
          }}
        />
        <Typography
          onClick={() => {
            window.location.href = "/";
          }}
          variant="h6"
          component="div"
          sx={{ color: "black", fontWeight: "bold", cursor: "pointer" }}
        >
          Paws
        </Typography>
      </Box>
      {user ? (
        <>
          {/* <Button
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/pawsai-pet-health-tracker/id6670763544",
                "_blank"
              )
            }
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "white",
              color: "black",
              fontWeight: "bold",
              textTransform: "none",
              padding: "4px 8px",
              borderRadius: "10px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: COLORS.blue,
                color: "white",
              },
              width: isMobileView ? "auto" : "auto",
              minWidth: isMobileView ? "100px" : "200px",
            }}
            startIcon={<AppleIcon sx={{ color: "inherit" }} />}
          >
            App Store
          </Button> */}
          <AvatarContainer onClick={handleClick}>
            <Avatar
              style={{ width: "40px", height: "40px" }}
              {...avatarConfig}
            />
          </AvatarContainer>
        </>
      ) : (
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            onClick={() => {
              if (!user) {
                setGlobalState({
                  ...globalState,
                  showAuthModal: true,
                });
                return;
              }

              window.location.href = "/add-animal";
            }}
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "white",
              color: "black",
              fontWeight: "bold",
              textTransform: "none",
              padding: "2px 8px",
              borderRadius: "10px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: COLORS.blue,
                color: "white",
              },
              width: "auto",
            }}
            startIcon={<AddIcon sx={{ color: "inherit" }} />}
          >
            Add a dog
          </Button>
          <Button
            onClick={() => {
              if (!user) {
                setGlobalState({
                  ...globalState,
                  showAuthModal: true,
                });
                return;
              }
              window.location.href = "/add-animal";
            }}
            variant="text"
            color="primary"
            sx={{
              color: "black",
              fontWeight: "bold",
              textTransform: "none",
              borderRadius: "10px",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: COLORS.blue,
                color: "white",
              },
              width: "auto",
            }}
          >
            Login
          </Button>
        </Box>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => (window.location.href = "/add-animal")}>
          Add an animal
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Popover>
    </Toolbar>
  );
};

export default CustomToolbar;
