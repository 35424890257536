import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  AppBar,
  Button,
  Container,
  CircularProgress,
  Divider,
} from "@mui/material";
import { keyframes, styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { firebase } from "../firebaseConfig";
import { COLORS } from "../utils/COLORS";
import CustomToolbar from "../components/CustomToolbar";
import Footer from "../components/Footer";
import { calculateCountdown } from "../utils/HELPER_FUNCTIONS";

const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.3; }
  100% { opacity: 1; }
`;

const PageContainer = styled(Container)({
  // paddingBottom: "40px",
  display: "flex",
  flexDirection: "column",
});

const Header = styled(AppBar)({
  backgroundColor: COLORS.yellow,
  boxShadow: "none",
});

const StyledCard = styled(Card)({
  height: "100%",
  display: "flex",
  cursor: "pointer",
  flexDirection: "column",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
});

const CardImageContainer = styled(Box)({
  position: "relative",
  paddingTop: "75%", // 4:3 aspect ratio
});

const CardImage = styled(CardMedia)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const StatusBadge = styled(Box)(({ status }) => ({
  position: "absolute",
  top: "10px",
  right: "10px",
  padding: "4px 8px",
  borderRadius: "12px",
  fontSize: "0.75rem",
  fontWeight: "bold",
  color: "#fff",
  backgroundColor:
    status === "rescued"
      ? COLORS.primaryGreen
      : status === "pending"
      ? COLORS.orange
      : COLORS.secondary,
}));

const BrowsePage = () => {
  const [needingRescue, setNeedingRescue] = useState([]);
  const [availablePets, setAvailablePets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [availableShelters, setAvailableShelters] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch available pets
        const petsSnapshot = await firebase
          .database()
          .ref("availablePets")
          .once("value");
        const petsData = petsSnapshot.val();
        const petsArray = petsData
          ? Object.entries(petsData).map(([id, pet]) => ({ id, ...pet }))
          : [];

        // Fetch available shelters
        const sheltersSnapshot = await firebase
          .database()
          .ref("availableShelters")
          .once("value");
        const sheltersData = sheltersSnapshot.val();
        setAvailableShelters(sheltersData || {});

        const currentDate = new Date();

        const sortedPets = sortPetsByKillDate(petsArray);

        const segmentedPets = sortedPets.reduce(
          (acc, pet) => {
            const countdown = calculateCountdown(pet);
            if (
              countdown !== "Time expired" &&
              pet.euthanasiaStatus !== "rescued"
            ) {
              acc.needingRescue.push(pet);
            } else {
              acc.others.push(pet);
            }
            return acc;
          },
          { needingRescue: [], others: [] }
        );

        setNeedingRescue(segmentedPets.needingRescue);
        setAvailablePets([
          ...segmentedPets.needingRescue,
          ...segmentedPets.others,
        ]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getStatusText = (pet) => {
    const countdown = calculateCountdown(pet);
    if (pet.euthanasiaStatus === "rescued") return "Rescued";
    if (pet.claimStatus === "pending") return "Claim in progress";
    if (pet.claimStatus === "accepted") return "Rescued!";
    if (countdown === "Time expired") return "Expired :(";
    return "Needs rescue";
  };

  const sortPetsByKillDate = (pets) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return pets.sort((a, b) => {
      const dateA = new Date(a.euthanasiaDate);
      const dateB = new Date(b.euthanasiaDate);

      // Helper function to categorize dates
      const getDateCategory = (date) => {
        if (date < today) return 3; // Past dates
        if (date.getTime() === today.getTime()) return 0; // Today
        if (date.getTime() === tomorrow.getTime()) return 1; // Tomorrow
        return 2; // Future dates
      };

      const categoryA = getDateCategory(dateA);
      const categoryB = getDateCategory(dateB);

      if (categoryA !== categoryB) {
        return categoryA - categoryB;
      }

      // If in the same category, sort by date
      return dateA - dateB;
    });
  };

  const getKillDate = (pet) => {
    if (!pet.euthanasiaDate) return "Missing date";

    const today = new Date();
    const [year, month, day] = pet.euthanasiaDate.split("-").map(Number);
    const killDate = new Date(Date.UTC(year, month - 1, day));

    const tomorrow = new Date(today);
    tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);

    const formattedDate = killDate.toLocaleDateString("en-US", {
      timeZone: "UTC",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    if (
      today.getUTCFullYear() === killDate.getUTCFullYear() &&
      today.getUTCMonth() === killDate.getUTCMonth() &&
      today.getUTCDate() === killDate.getUTCDate()
    ) {
      return `TODAY (${formattedDate})`;
    } else if (
      tomorrow.getUTCFullYear() === killDate.getUTCFullYear() &&
      tomorrow.getUTCMonth() === killDate.getUTCMonth() &&
      tomorrow.getUTCDate() === killDate.getUTCDate()
    ) {
      return `TOMORROW (${formattedDate})`;
    }
    return formattedDate;
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PageContainer maxWidth="lg">
      <Header sx={{ width: "100vw", paddingX: "3%" }}>
        <CustomToolbar />
      </Header>
      <Typography
        sx={{
          fontWeight: "bold",
          color: COLORS.lightGray4,
          fontSize: "24px",
          mb: 2,
          mt: 10,
        }}
      >
        Urgent Need Dogs
      </Typography>
      <Grid container spacing={4}>
        {availablePets.map((pet) => (
          <Grid item xs={12} sm={6} md={4} key={pet.id} sx={{ height: "100%" }}>
            <StyledCard onClick={() => window.open(`/${pet.slug}`, "_blank")}>
              <CardImageContainer>
                <CardImage
                  image={
                    pet.images?.[0] || "https://via.placeholder.com/300x225"
                  }
                  title={pet.name}
                />
                <StatusBadge status={getStatusText(pet).toLowerCase()}>
                  {getStatusText(pet)}
                </StatusBadge>
              </CardImageContainer>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  {pet.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {pet.breed} • {pet.age} • {pet.weight} lbs
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {availableShelters[pet.shelterId]?.name || "Unknown Shelter"}
                </Typography>
                {pet.euthanasiaStatus !== "rescued" && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      mt: 1,
                      color: COLORS.secondary,
                      animation:
                        calculateCountdown(pet) !== "Time expired"
                          ? `${blinkAnimation} 1.5s ease-in-out infinite`
                          : "none",
                    }}
                  >
                    Kill Date: {getKillDate(pet)}
                  </Typography>
                )}
              </CardContent>
              <Box sx={{ p: 2, pt: 0 }}>
                <Button
                  onClick={() => window.open(`/${pet.slug}`, "_blank")}
                  fullWidth
                  variant="contained"
                  sx={{
                    bgcolor: COLORS.primaryGreen,
                    "&:hover": { bgcolor: COLORS.darkGreen },
                  }}
                >
                  View Profile
                </Button>
              </Box>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      <Divider sx={{ mb: 10 }} />
      <Footer />
    </PageContainer>
  );
};

export default BrowsePage;
