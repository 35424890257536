import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { toast } from "react-simple-toasts";
import { firebase, functions } from "../firebaseConfig";
import {
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  Paper,
} from "@mui/material";
import { CheckCircleOutline, CloseOutlined } from "@mui/icons-material";
import { COLORS } from "../utils/COLORS";
import { httpsCallable } from "firebase/functions";
import { Link } from "react-router-dom"; // Add this import at the top of your file

const AdminPage = () => {
  const [data, setData] = useState({});
  const [avPets, setAvPets] = useState([]);
  const [hasAccess, setHasAccess] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);

  const [requiresCapturePledges, setRequiresCapturePledges] = useState([]);
  const [unprocessedClaims, setUnprocessedClaims] = useState([]);

  useEffect(() => {
    const checkUserAuth = () => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user && user.email === "admin@admin.com") {
          // User is signed in and is the admin
          setUser(user);
          setHasAccess(true);
          // User is admin, fetch all database data
          const fetchAllData = async () => {
            try {
              const snapshot = await firebase.database().ref("/").once("value");
              const allData = snapshot.val();
              setData(allData);

              // Process claims
              const allClaims = allData?.claims
                ? Object.values(allData.claims)
                : [];

              const pendingClaims = allClaims.filter(
                (claim) => claim?.claimStatus === "pending"
              );

              // Process pledges
              const allPledges = allData?.pledges
                ? Object.values(allData.pledges)
                : [];
              const capturePledges = allPledges.filter(
                (pledge) => pledge?.status === "requires_capture"
              );

              setRequiresCapturePledges(capturePledges);

              setAvPets(allData?.availablePets);
              const matchedPledgesAndClaims = pendingClaims.map((claim) => {
                const matchedPledges = capturePledges.filter(
                  (pledge) => pledge.animalId === claim.animalId
                );
                return { claim, pledges: matchedPledges };
              });

              // Fetch available pet profiles for matched pledges and claims
              const availablePetProfiles = await Promise.all(
                matchedPledgesAndClaims.map(async ({ claim }) => {
                  const petSnapshot = await firebase
                    .database()
                    .ref(`availablePets/${claim.slug}`)
                    .once("value");
                  return petSnapshot.val();
                })
              );

              // Combine matched pledges and claims with available pet profiles
              const combinedData = matchedPledgesAndClaims.map(
                (item, index) => ({
                  ...item,
                  petProfile: availablePetProfiles[index],
                })
              );

              setUnprocessedClaims(combinedData);
            } catch (error) {
              console.error("Error fetching data:", error);
              toast("Failed to fetch database data. Please try again.");
            }
          };
          fetchAllData();
        } else {
          // User is not admin or not signed in
          setUser(null);
          setHasAccess(false);
        }
      });
    };

    checkUserAuth();

    // Clean up the listener on unmount
    return () => {
      firebase.auth().onAuthStateChanged(() => {});
    };
  }, []);


  const handleSignIn = async () => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      const user = firebase.auth().currentUser;
      if (!user?.uid) {
        toast("No user found");
        return;
      }

      const userData = await firebase
        .database()
        .ref("users/" + user.uid)
        .once("value");

      const userDataObj = userData.val();

      setUser(userDataObj);
      toast("Successfully signed in!");
      setHasAccess(true);
    } catch (error) {
      console.error("Error signing in:", error);
      if (error.code === "auth/wrong-password") {
        toast("Incorrect password. Please try again.");
      } else {
        toast(
          error.message ||
            "An error occurred while signing in. Please try again."
        );
      }
      throw error; // Re-throw the error to be caught in handleSubmit
    }
  };

  const handleAcceptClaim = async (petId, claimId, claim) => {
    try {
      const pet =
        avPets && Object.values(avPets).find((pet) => pet.id === petId);

      if (!pet) {
        console.error("Pet not found for petId:", petId);
        toast("Pet not found");
        return;
      }

      // Process each pledge for the pet
      const pledges = requiresCapturePledges

     
      for (const [pledgeId, pledge] of Object.entries(pledges)) {
        if (pledge.status === "requires_capture") {
          try {
            const processPayment = httpsCallable(functions, "processPayment");
            const paymentResult = await processPayment({
              orderId: pledge?.orderId,
              total: pledge.displayAmount,
              customerId: pledge.customerId,
              paymentIntentId: pledge.paymentIntentId,
              vendorStripeAccountId: claim.claimedStripe,
              uid: claim.claimedBy,
              dogSlug: pledge?.dogSlug,
            });

            if (!paymentResult.data.success) {
              throw new Error(
                paymentResult.data.error ||
                  `Failed to process payment for pledge ${pledge?.orderId}`
              );
            }
          } catch (paymentError) {
            console.error(
              `Error processing payment for pledge ${pledge?.orderId}:`,
              paymentError
            );
            // You might want to handle this error differently, e.g., continue processing other pledges
            // For now, we'll throw the error to stop the process
            throw paymentError;
          }
        } else {
          console.log(`Pledge ${pledge?.orderId} does not require capture, skipping`);
        }
      }

      console.log("All pledges processed, updating pet status");

      // Update the pet's claim status in the database
      await firebase.database().ref(`availablePets/${pet.slug}`).update({
        claimStatus: "accepted",
        euthanasiaStatus: "rescued",
      });

      // update the claim status to accepted
      await firebase
        .database()
        .ref(`availablePets/${pet.slug}/claims/${claimId}`)
        .update({
          claimStatus: "accepted",
        });

      // update the claims
      await firebase.database().ref(`claims/${claimId}`).update({
        claimStatus: "accepted",
      });

      // Iterate through all pledges and update their status
      const claimPledges = requiresCapturePledges
      for (const [pledgeId, pledge] of Object.entries(claimPledges)) {
        await firebase.database().ref(`pledges/${pledge?.orderId}`).update({
          status: "processed",
        });

        // Update pledge status in availablePets/slug/pledges
        await firebase
          .database()
          .ref(`availablePets/${pet.slug}/pledges/${pledge?.orderId}`)
          .update({
            status: "processed",
          });

        // Update the user's profile to add the status of the animal
        await firebase
          .database()
          .ref(`users/${pledge.userId}/pledges/${pet.slug}/${pledge?.orderId}`)
          .update({
            rescueDate: firebase.database.ServerValue.TIMESTAMP,
            status: "processed",
          });
      }

      // Update local state
      setAvPets((prevPets) => ({
        ...prevPets,
        [petId]: {
          ...prevPets[petId],
          claimStatus: "accepted",
          euthanasiaStatus: "rescued",
        },
      }));

      console.log("Claim accepted and pledges processed successfully");
      toast("Claim accepted and pledges processed successfully");
    } catch (error) {
      console.error("Error accepting claim:", error);
      toast("Failed to accept claim. Please try again.");
    }
  };

  const handleDenyClaim = (petId) => {
    // TODO: Implement deny claim logic
    console.log("Denied claim for pet:", petId);
    toast("Claim denied");
  };

  const calculateTotalPledged = (pledges) => {
    if (!pledges) return 0;
    return Object.values(pledges).reduce((total, pledge) => {
      if (pledge.status === "requires_capture") {
        return total + (pledge.displayAmount || 0);
      }
      return total;
    }, 0);
  };

  if (!hasAccess) {
    return (
      <Container>
        <Title>Admin Page</Title>
        <InputContainer>
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            placeholder="Pass"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button onClick={handleSignIn}>LOGIN</Button>
        </InputContainer>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Admin Dashboard
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
        Pending Claims
      </Typography>
      {requiresCapturePledges && requiresCapturePledges.length > 0 ? (
        <Grid container spacing={3}>
          {unprocessedClaims.map((data) => {
            const pet = data.petProfile;
            const claim = data.claim;
            const pledges = data.pledges;

            return (
              <Grid item xs={12} key={pet.id}>
                <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                  <Typography
                    variant="h6"
                    component={Link}
                    to={`https://paws.lol/${pet.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      color: "inherit",
                      textDecoration: "none",
                      "&:hover": { color: "primary.main" },
                    }}
                  >
                    {pet.name}{" "}
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ ml: 1 }}
                    >
                      (#{pet.animalId})
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ ml: 1 }}
                    >
                      ({pledges ? pledges.length : 0} pledges to process)
                    </Typography>
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {pet.breed} • {pet.age} years old
                  </Typography>

                  <Box sx={{ borderTop: "1px solid #eee", mt: 2, pt: 2 }}>
                    {pledges &&
                      Object.entries(pledges).map(
                        ([pledgeId, pledge], index) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              key={pledgeId}
                              sx={{
                                mb: 1,
                                mt: 1,
                                fontSize: "14px",
                                color: "text.secondary",
                              }}
                            >
                              ${pledge.displayAmount}
                            </Typography>
                            <Typography
                              key={pledgeId}
                              sx={{
                                mb: 1,
                                mt: 1,
                                fontSize: "14px",
                                color: "text.secondary",
                              }}
                            >
                              {new Date(pledge.createdAt).toLocaleString()}
                            </Typography>
                          </Box>
                        )
                      )}
                  </Box>

                  {Object.entries(pet.claims || {}).map(
                    ([claimId, claim]) =>
                      claim.claimStatus === "pending" && (
                        <Box key={claimId} sx={{ mt: 2, pt: 2 }}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={6}>
                              <Typography
                                sx={{
                                  mb: 1,
                                  mt: 1,
                                  fontSize: "14px",
                                  color: "text.secondary",
                                }}
                              >
                                Claim ID: {claimId}
                              </Typography>
                              <Typography
                                sx={{
                                  mb: 1,
                                  mt: 1,
                                  fontSize: "14px",
                                  color: "text.secondary",
                                }}
                              >
                                Claimed By: {claim.claimedBy}
                              </Typography>
                              <Typography
                                sx={{
                                  mb: 1,
                                  mt: 1,
                                  fontSize: "14px",
                                  color: "text.secondary",
                                }}
                              >
                                Claimed At:{" "}
                                {new Date(claim.claimedAt).toLocaleString()}(
                                {Math.floor(
                                  (Date.now() - claim.claimedAt) / 3600000
                                )}{" "}
                                hours ago)
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  gap: 2,
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="success"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Are you sure you want to accept this claim?"
                                      )
                                    ) {
                                      handleAcceptClaim(pet.id, claimId, claim);
                                    }
                                  }}
                                >
                                  Accept
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() =>
                                    handleDenyClaim(pet.id, claimId)
                                  }
                                >
                                  Deny
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                          <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Proof of Rescue:
                            </Typography>
                            <img
                              src={claim.proofOfRescueUrl}
                              alt="Proof of Rescue"
                              style={{
                                width: "100%",
                                maxHeight: "300px",
                                objectFit: "cover",
                                borderRadius: "4px",
                              }}
                            />
                          </Box>
                        </Box>
                      )
                  )}
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
          <Typography variant="h6" align="center">
            No pending claims
          </Typography>
        </Paper>
      )}
    </Container>
  );
};

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const DataContainer = styled.div`
  margin-top: 20px;
`;

const DataItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
`;

export default AdminPage;
