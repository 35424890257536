import { Box, Typography } from "@mui/material";
import { COLORS } from "../utils/COLORS";
import { styled } from "@mui/system";

const Terms = styled(Typography)({
  bottom: "14px",
  fontSize: "12px",
  cursor: "pointer",
  color: "#000", // Added color for better UI/UX
});

const Footer = () => {
  const isMobileView = window.innerWidth < 600;

  return (
    <Box
    sx={{
    //   bottom: 5,
    //   left: 0,
    //   right: 0,
      display: "flex",
      justifyContent: "center",
      width: "100%",
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        width: isMobileView ? "90vw" : "40vw",
      }}
    >
      <Terms
        onClick={() => (window.location.href = "/privacy")}
        variant="body2"
        sx={{
          marginBottom: isMobileView ? "10px" : 0,
          color: COLORS.lightGray4,
        }}
      >
        Privacy Policy
      </Terms>

    

      <Terms
        onClick={() => (window.location.href = "/contact")}
        variant="body2"
        style={{ color: COLORS.lightGray4 }}
      >
        Contact Us
      </Terms>
      </Box>
    </Box>
  );
};

export default Footer;
