import React, { useContext, useState } from "react";
import {
  Modal,
  Slider,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import GlobalStateContext from "../context/globalStateContext";
import { COLORS } from "../utils/COLORS";
import { functions } from "../firebaseConfig";
import { httpsCallable } from "firebase/functions";
import { firebase } from "../firebaseConfig";
import UserContext from "../context/userContext";
import { toast } from "react-simple-toasts";

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
  width: "90%",
  maxWidth: "400px",
  maxHeight: "80vh",
  overflowY: "auto",
  textAlign: "center",
}));

const PledgeModal = () => {
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [pledgeAmount, setPledgeAmount] = useState(50);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setGlobalState({ ...globalState, showPledgeModal: false });
  };

  const handleSliderChange = (event, newValue) => {
    setPledgeAmount(newValue);
  };

  const handleConfirmPledge = async () => {
    setIsProcessing(true);
    setError(null);

    console.log("----", globalState?.selectedDog);
    try {
      const createCustomerForDelayedCharge = httpsCallable(
        functions,
        "createCustomerForDelayedCharge"
      );
      const result = await createCustomerForDelayedCharge({
        email: user.email,
        amount: pledgeAmount * 100, // Convert to cents
        paymentMethodId: user.stripePaymentMethodId,
        dogSlug: globalState.selectedDog?.slug,
        orderDetails: {
          dogName: globalState.selectedDog?.name,
          pledgeAmount: pledgeAmount,
        },
      });

      const { success, orderId, orderData } = result.data;

      if (success) {
        // Save the pledge data to your database
        await firebase
          .database()
          .ref(
            `users/${user.uid}/pledges/${globalState.selectedDog?.slug}/${orderId}`
          )
          .set({
            ...orderData,
            displayAmount: pledgeAmount,
            createdAt: new Date().toISOString(),
          });

        // Save the pledge to the dog's data
        await firebase
          .database()
          .ref(
            `availablePets/${globalState.selectedDog?.slug}/pledges/${orderId}`
          )
          .set({
            ...orderData,
            userId: user.uid,
            userEmail: user.email,
            userName: user.username,
            displayAmount: pledgeAmount,
            createdAt: new Date().toISOString(),
          });

        // save to all pledge
        await firebase
          .database()
          .ref(`pledges/${orderId}`)
          .set({
            ...orderData,
            userId: user.uid,
            userEmail: user.email,
            userName: user.username,
            displayAmount: pledgeAmount,
            createdAt: new Date().toISOString(),
            // Beta tag fields
            status: "requires_capture",
            slug: globalState.selectedDog?.slug,
            name: globalState.selectedDog?.name,
            breed: globalState.selectedDog?.breed,
            age: globalState.selectedDog?.age,
            animalId: globalState.selectedDog?.animalId,
          });

        handleClose();
        toast("Pledge successful!");
      } else {
        throw new Error("Failed to create pledge");
      }
    } catch (err) {
      setError(err.message);
      console.error("Pledge error:", err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <StyledModal
      open={globalState.showPledgeModal}
      onClose={handleClose}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
      }}
    >
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        exit={{ y: "100%" }}
        onClick={handleClose}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: window.innerWidth > 600 ? "center" : "flex-end",
          height: "100%",
        }}
      >
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <Typography variant="h5" gutterBottom>
            Pledge to Save {globalState.selectedDog?.name}
          </Typography>
          <Typography variant="body1" paragraph>
            You're only charged when a PROOF OF RESCUE is uploaded. We'll noity
            you.
          </Typography>
          <Slider
            value={pledgeAmount}
            onChange={handleSliderChange}
            aria-labelledby="pledge-amount-slider"
            valueLabelDisplay="auto"
            step={5}
            marks
            min={5}
            max={100}
            sx={{ mb: 2 }}
          />
          <Typography variant="h6" gutterBottom>
            ${pledgeAmount}
          </Typography>
          <Button
            variant="contained"
            fullWidth
            onClick={handleConfirmPledge}
            sx={{
              mt: 2,
              backgroundColor: COLORS.primaryGreen,
              "&:hover": {
                backgroundColor: COLORS.darkGreen,
              },
            }}
          >
            {isProcessing ? (
              <CircularProgress sx={{ color: COLORS.white }} />
            ) : (
              "Confirm Pledge"
            )}
          </Button>
        </ModalContent>
      </motion.div>
    </StyledModal>
  );
};

export default PledgeModal;
