import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { GlobalStateProvider } from "./context/globalStateContext";
import { UserContextProvider } from "./context/userContext";
import "./global.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import LandingPage from "./pages/LandingPage";
import ContactPage from "./pages/ContactPage";
import PrivacyPage from "./pages/PrivacyPage";
import BrowsePage from "./pages/BrowsePage";
import DogProfilePage from "./pages/DogProfilePage";
import AdminPage from "./pages/AdminPage";
import AddAnimalPage from "./pages/AddAnimalPage";
import MissionPage from "./pages/MissionPage";
import ReactGA from "react-ga";
import { useEffect } from "react";

const theme = createTheme();

function App() {
  useEffect(() => {
    ReactGA.initialize("G-5KJD49N10J");

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });

    const handleRouteChange = () => {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    };

    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <GlobalStateProvider>
          <UserContextProvider>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/browse" element={<BrowsePage />} />
              <Route path="/:slug" element={<DogProfilePage />} />
              <Route
                path="/add-animal"
                exact={true}
                element={<AddAnimalPage />}
              />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/mission" element={<MissionPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
            </Routes>
          </UserContextProvider>
        </GlobalStateProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
