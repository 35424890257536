import React, { useContext, useState } from "react";
import {
  Modal,
  Button,
  Typography,
  Box,
  CircularProgress,
  Stepper,
  Step,
  useMediaQuery,
  StepLabel,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import GlobalStateContext from "../context/globalStateContext";
import UserContext from "../context/userContext";
import { firebase, database, storage } from "../firebaseConfig";
import { COLORS } from "../utils/COLORS";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { guidGenerator } from "../utils/HELPER_FUNCTIONS";

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
}));

const ModalContent = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
  width: "90%",
  maxWidth: "500px",
  maxHeight: "80vh",
  overflowY: "auto",
}));

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const ImagePreview = styled("img")({
  width: "100%",
  maxHeight: "200px",
  objectFit: "cover",
  borderRadius: "4px",
  marginTop: "16px",
});

const ClaimFundsModal = () => {
  const { user } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [proofImage, setProofImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setGlobalState({ ...globalState, showClaimFundsModal: false });
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setProofImage(e.target.files[0]);
      setActiveStep(1);
    }
  };

  const handleClaimFunds = async () => {
    if (!proofImage) {
      alert("Please upload proof of rescue");
      return;
    }

    setUploading(true);

    try {
      const storageRef = storage.ref(
        `rescue_proofs/${globalState.selectedDog?.id}`
      );
      const uploadTask = await storageRef.put(proofImage);
      const imageUrl = await uploadTask.ref.getDownloadURL();
      const claimId = guidGenerator();

      await database
        .ref(`availablePets/${globalState.selectedDog?.slug}/claims/${claimId}`)
        .update({
          claimId: claimId,
          claimStatus: "pending",
          proofOfRescueUrl: imageUrl,
          claimedBy: user.uid,
          claimedAt: firebase.database.ServerValue.TIMESTAMP,
          claimedStripe: user?.stripe_account_id || "no stripe id",
        });

      // save to its own claims node
      await database.ref(`claims/${claimId}`).set({
        claimId: claimId,
        claimStatus: "pending",
        proofOfRescueUrl: imageUrl,
        animalId: globalState.selectedDog?.animalId,
        slug: globalState.selectedDog?.slug,
        claimedBy: user.uid,
        claimedAt: firebase.database.ServerValue.TIMESTAMP,
        claimedStripe: user?.stripe_account_id || "no stripe id",
      });

      setActiveStep(2);
      setTimeout(() => {
        setGlobalState({ ...globalState, showClaimFundsModal: false });
      }, 3000);
    } catch (error) {
      console.error("Error claiming dog:", error);
      alert("An error occurred while claiming the dog. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const steps = ["Upload Proof", "Confirm", "Complete"];

  return (
    <StyledModal
      open={globalState.showClaimFundsModal}
      onClose={handleClose}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
      }}
    >
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        exit={{ y: "100%" }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        onClick={handleClose}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: isMobileView ? "flex-end" : "center",
          height: "100%",
        }}
      >
        <ModalContent
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{ duration: 0.3 }}
          onClick={(e) => e.stopPropagation()}
        >
          <Typography variant="h5" gutterBottom align="center">
            Claim {globalState.selectedDog?.name}'s Rescue
          </Typography>

          <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
            <>
              <Typography variant="body1" paragraph align="center">
                Please upload proof of rescue to claim funds.
              </Typography>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="raised-button-file">
                <UploadButton
                  variant="outlined"
                  component="span"
                  fullWidth
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Proof of Rescue
                </UploadButton>
              </label>
            </>
          )}

          {activeStep === 1 && (
            <>
              <Typography variant="body1" paragraph align="center">
                Confirm your proof of rescue and claim funds.
              </Typography>
              {proofImage && (
                <ImagePreview
                  src={URL.createObjectURL(proofImage)}
                  alt="Proof of Rescue"
                />
              )}
              <Button
                variant="contained"
                fullWidth
                onClick={handleClaimFunds}
                disabled={uploading}
                sx={{
                  mt: 2,
                  backgroundColor: COLORS.primaryGreen,
                  "&:hover": {
                    backgroundColor: COLORS.darkGreen,
                  },
                }}
              >
                {uploading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  `Claim ${globalState.selectedDog?.name}`
                )}
              </Button>
            </>
          )}

          {activeStep === 2 && (
            <>
              <Typography variant="h6" gutterBottom align="center">
                Claim Submitted Successfully!
              </Typography>
              <Typography variant="body1" paragraph align="center">
                Thank you for rescuing {globalState.selectedDog?.name}. We'll
                review & proces your claim asap.
              </Typography>
            </>
          )}
        </ModalContent>
      </motion.div>
    </StyledModal>
  );
};

export default ClaimFundsModal;
